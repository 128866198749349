@use 'src/styles/theme';

//
// @url https://github.com/Necromancerx/media-queries-scss-mixins/blob/master/media-queries.scss
//

// media devices
@mixin xs {
  @media screen and (max-width: #{theme.$screen-xs-max}) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: #{theme.$screen-sm-min}) and (max-width: #{theme.$screen-sm-max}) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: #{theme.$screen-md-min}) and (max-width: #{theme.$screen-md-max}) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: #{theme.$screen-lg-min}) and (max-width: #{theme.$screen-lg-max}) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: #{theme.$screen-xl-min}) and (max-width: #{theme.$screen-xl-max}) {
    @content;
  }
}

// media lt queries
@mixin lt-sm {
  @media screen and (max-width: #{theme.$screen-xs-max}) {
    @content;
  }
}

@mixin lt-md {
  @media screen and (max-width: #{theme.$screen-sm-max}) {
    @content;
  }
}

@mixin lt-lg {
  @media screen and (max-width: #{theme.$screen-md-max}) {
    @content;
  }
}

@mixin lt-xl {
  @media screen and (max-width: #{theme.$screen-lg-max}) {
    @content;
  }
}

// media gt queries
@mixin gt-xs {
  @media screen and (min-width: #{theme.$screen-sm-min}) {
    @content;
  }
}

@mixin gt-sm {
  @media screen and (min-width: #{theme.$screen-md-min}) {
    @content;
  }
}

@mixin gt-md {
  @media screen and (min-width: #{theme.$screen-lg-min}) {
    @content;
  }
}

@mixin gt-lg {
  @media screen and (min-width: #{theme.$screen-xl-min}) {
    @content;
  }
}
