.main-layout {
  width: 100%;
}

.main-layout-inner-container {
  height: 100%;
  width: 100%;
}

@media screen and(max-width: 1024px) {
  .main-layout {
    height: 100%;
  }
}
