@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/labels';
@use 'src/styles/mixins/mq';

.box-program-container {
  h5 {
    margin-top: theme.$spacing-base;
    color: colors.$neutral-900;
    font-size: 10px;
    font-weight: 600;
    line-height: 1.6;
  }

  .bx-program-divisor {
    margin: theme.$spacing-base 0;
  }

  .bx-prog-content {
    display: grid;
    grid-gap: theme.$spacing-xl;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    margin-top: 30px;

    .bx-prog-content-right {
      grid-column-start: 2;
      grid-column-end: 3;
    }

    .bx-prog-content-left {
      grid-column-start: 1;
      grid-column-end: 2;
    }

    //TODO: this is WIP
    @include mq.lt-sm {
      grid-template-rows: auto;
      grid-template-columns: 1fr;

      .bx-prog-content-right {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 2;
      }

      .bx-prog-content-left {
        grid-row-start: 1;
        grid-row-end: 2;
      }
    }

    .bx-prog-content-title-row {
      align-items: center;
      display: flex;

      .market-place-tooltip {
        margin-left: 5px;

        &.unit-tooltip {
          .tooltiptext {
            left: -150px;

            &:after {
              left: 153px;
            }
          }

          @include mq.lt-sm {
            .tooltiptext {
              left: 56%;
              transform: translateX(-56%);

              &:after {
                left: 54%;
              }
            }
          }
        }
      }
    }

    .bx-prog-cntnt-select-error {
      color: colors.$red-900;
      font-size: theme.$fsize-3;
      line-height: 1.23;
      margin-bottom: 15px;
    }

    .bx-prog-contnt-select-error-true {
      button {
        border-color: colors.$red-900;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      align-content: space-around;
    }

    h3 {
      color: colors.$neutral-700;
      font-size: theme.$fsize-3;
    }

    .frida-tip-container {
      background-color: colors.$primary-50;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      padding: theme.$spacing-l theme.$spacing-l 40px;

      .frida-tip-title {
        color: colors.$primary-600;
        font-size: theme.$fsize-base;
        font-weight: 600;
        line-height: 1.5;
        text-align: left;
      }

      .frida-tip-text {
        color: colors.$neutral-900;
        font-size: theme.$fsize-4;
        line-height: 1.33;
        padding: theme.$spacing-base 0;

        &:nth-of-type(3) {
          padding-bottom: 0;
        }
        b {
          font-weight: 700;
        }
      }
      @include mq.lt-sm {
        display: none;
      }
    }

    .bx-prog-contnt-select-container {
      position: relative;
      flex-direction: column;
      margin-top: theme.$spacing-m;

      [class^='sl-floating-modal-container-'] {
        overflow: auto;
        max-height: 200px;
      }

      .bx-prog-contnt-select {
        .bx-prog-cntnt-slect-icon {
          height: 24px;
          width: 24px;
        }
      }
    }

    .bx-prog-cntnt-box-type-label {
      color: colors.$neutral-800;
      font-size: theme.$fsize-6;
      line-height: 1.6;
      margin: theme.$spacing-base 0;
    }

    .bx-prog-contnt-data-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: theme.$spacing-m;
      margin-top: theme.$spacing-m;

      .bx-prog-contnt-data-value {
        color: colors.$neutral-600;
        font-size: theme.$fsize-4;
        line-height: 16px;
        display: inline;
      }

      h3 {
        color: colors.$neutral-800;
        font-size: theme.$fsize-base;
        display: inline;
        text-align: right;
        float: right;
      }

      .bx-prog-contnt-dta-rw-inline {
        align-items: center;
        display: flex;

        .market-place-tooltip {
          margin-left: 5px;

          @include mq.lt-sm {
            .tooltiptext {
              left: 55%;
              transform: translateX(-55%);

              &:after {
                left: 56%;
                transform: translateX(-56%);
              }
            }
          }
        }
      }
    }

    .bx-prog-contnt-data-row-total {
      display: flex;
      flex-direction: column;
      margin-top: theme.$spacing-base;
      text-align: right;

      .bx-prog-cntnt-data-flat-row {
        align-items: center;
        display: flex;
        justify-content: flex-end;

        .market-place-tooltip {
          position: relative;

          .tooltiptext {
            left: -251px;

            &:after {
              left: 254px;
            }
          }

          @include mq.lt-sm {
            .tooltiptext {
              left: -250px;
            }
          }
        }
      }

      .bx-prog-contnt-data-label {
        align-items: center;
        color: colors.$neutral-900;
        display: flex;
        font-size: theme.$fsize-4;
        font-weight: 400;
        justify-content: flex-end;
      }

      .market-place-tooltip {
        margin-left: 5px;
      }

      .bx-prog-cntnt-data-inner-row {
        align-content: center;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .bx-prog-contnt-data-description {
          margin-top: 15px;
        }
      }

      .bx-prog-contnt-data-description {
        color: colors.$neutral-900;
        font-size: theme.$fsize-6;
        line-height: 1.6;
        margin-left: 5px;
      }

      .bx-prog-cntnt-data-value {
        @extend .price-label;
        font-weight: 700;
        font-size: theme.$fsize3;
        color: colors.$neutral-900;
        margin: theme.$spacing-base 0;

        &:before {
          font-size: theme.$fsize-base;
          margin-right: 3px;
        }
      }
    }
  }
}

@include mq.lt-sm {
  .market-place-tooltip {
    &.weekly-sale-delivery-price {
      .tooltiptext {
        left: 98%;
        transform: translateX(-98%);

        &:after {
          left: 93%;
        }
      }
    }
  }
}
