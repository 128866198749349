@use 'src/styles/theme';
@use 'src/styles/colors';

.option-list__header__title {
  font-size: theme.$fsize1;
  font-weight: 700;
  color: colors.$neutral-900;
}

.option-list__content__container {
  margin: theme.$spacing-m 0;
}

.option-list__button__container {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: unset;
  border: 1px solid transparent;
  padding: theme.$spacing-base theme.$spacing-m theme.$spacing-base theme.$spacing-l;
  min-height: theme.$spacing-2xl;
  border-style: none;
  margin-bottom: theme.$fsize-base;
  border-radius: theme.$spacing-l;
  background-color: colors.$neutral-50;
  width: 100%;

  &.active-true {
    background-color: colors.$primary-900;
    .option-list__button__label {
      color: colors.$white-100;
      font-weight: 600;
    }

    &.warning-true {
      border-color: #e61e39;
      background-color: colors.$red-700;
    }

    &:hover {
      box-shadow: unset;
    }
  }

  &.active-false {
    &:focus,
    &:hover {
      background-color: colors.$primary-50;
    }

    &.warning-true {
      &:focus,
      &:hover {
        background-color: colors.$red-50;
      }
    }
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 3px colors.$green-800-60;
  }
}

.option-list__button__label {
  flex: 1;
  font-size: theme.$fsize-4;
  line-height: 1.33;
  text-transform: uppercase;
  font-weight: 500;
  color: colors.$primary-900;
}
