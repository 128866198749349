@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/labels';

.freshboard-table-internal-container {
  border-radius: 24px;
}

.table-responsive {
  display: none;
}

.freshboard-table-internal-container {
  table {
    border-collapse: collapse;
    margin-top: 10px;
    table-layout: auto;
    width: 100%;
  }

  th {
    color: colors.$neutral-700;
    font-size: theme.$fsize-4;
    line-height: 1.33;
  }

  td {
    color: colors.$neutral-900;
    font-size: theme.$fsize3;
    font-weight: 600;
    line-height: 1.5;
  }

  th,
  td {
    border-right: 1px solid colors.$neutral-300;
    padding-left: 40px;
    padding-right: 40px;
    text-align: left;
  }

  th:last-child,
  td:last-child {
    border-right: 0;
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
  }
}

.table-versus-behavior-icon {
  height: 24px;
  width: 24px;
}

.table-versus-behavior-percentage {
  @extend .percent-label;
}

.table-versus-behavior-price-versus {
  display: flex;
}

.table-date {
  display: flex;
  font-size: theme.$fsize-4;

  span {
    display: inherit;
    margin-right: theme.$spacing-m;
  }

  strong {
    margin-left: 5px;
    font-size: theme.$fsize-3;
  }

  .table-date-calendar-icon {
    height: 16px;
    margin-right: 5px;
    width: 16px;
  }

  .table-date-states-icon {
    height: 18px;
    margin-left: 20px;
    margin-right: 5px;
    width: 18px;
  }
}

@media screen and (max-width: 700px) {
  .table-desktop {
    display: none;
  }

  .table-responsive {
    display: inherit;
  }

  .freshboard-table-internal-container {
    th,
    td {
      border: none;
    }
  }
}

@media screen and (max-width: 700px) {
  .table-date {
    flex-direction: column;
    align-items: flex-start;

    .table-date-states-icon {
      margin-left: 0;
    }
  }
  .freshboard-table-internal-container {
    td,
    th {
      padding: 0 theme.$spacing-base;
    }
  }
}
@media screen and (max-width: 320px) {
  .freshboard-table-internal-container {
    td {
      font-size: theme.$fsize2;

      &:before {
        font-size: theme.$fsize2;
      }
    }
  }
}
