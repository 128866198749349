@use 'src/styles/theme';
@use 'src/styles/colors';

.locations-container {
  min-height: 425px;
}

.locations-graph-container {
  position: relative;
}

.locations-graph-custom-yaxis-label {
  color: colors.$neutral-700;
  font-size: theme.$fsize-6;
  font-weight: 500;
  height: 24px;
  line-height: 2.4;
  margin-left: 10px;
}

.locations-graph-description {
  border-radius: 16px;
  border: solid 1px colors.$primary-100;
  margin: theme.$spacing-m 0;
  padding: theme.$spacing-m;
  width: 50%;

  p {
    color: colors.$neutral-900;
    font-size: theme.$fsize-3;
  }
}

@media screen and (max-width: 1024px) {
  .locations-graph-description {
    max-width: 650px;
    width: 89%;
  }
}

@media screen and (max-width: 700px){
  .hc-custom {
    display: flex;
    align-items: center;
    width: 250px;

    p {
      font-size: 11px;
      margin-left: 4px;
      width: 200px;
      white-space: normal;
    }
  }
}

