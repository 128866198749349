@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/mixins/mq';

.management-details__container {
  box-shadow: 0 16px 24px -24px rgba(18, 13, 209, 0.8);

  @include mq.lt-sm {
    display: none;
    z-index: theme.$details-panel-index;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: theme.$spacing-m 10px;
    overflow: auto;
    background-color: colors.$white-100;

    &.active {
      display: inherit;
    }
  }
}
