@use 'src/styles/theme';
@use 'src/styles/colors';

.graph-no-data-container {
  align-content: center;
  align-items: center;
  background-color: colors.$white-100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .grph-no-dta-header {
    margin-bottom: theme.$spacing-base;
    .grph-no-dta-title {
      color: colors.$neutral-800;
      font-size: theme.$fsize-base;
      line-height: 1.5;
    }
  }
  .grph-no-dta-content {
    max-width: 562px;
    .graph-no-dta-description {
      color: colors.$neutral-800;
      font-size: theme.$fsize-2;
      line-height: 1.33;
    }
  }
}
