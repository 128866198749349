@use 'src/styles/theme';
@use 'src/styles/colors';

.market-header {
  max-width: 614px;
  color: colors.$white-100;
  background-color: colors.$primary-900;
  border-radius: 24px;
  box-shadow: 0 32px 16px -24px colors.$neutral-800-10, 0 16px 16px -24px colors.$neutral-800-30;

  &__top {
    display: flex;
    align-items: center;
    padding: theme.$spacing-m;
    background-color: colors.$primary-800;
    border-radius: inherit;
  }

  &__bottom {
    padding: theme.$spacing-m;
  }

  .dt-picker-calendar-icon-default path,
  .dt-picker-calendar-icon-edit rect {
    fill: colors.$white-100;
  }

  .dt-picker-button {
    color: colors.$primary-50;
    background-color: inherit;
  }
}

.market-header__product-oval {
  width: 40px;
  height: 40px;
  border: 2px solid colors.$white-100;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: theme.$spacing-base;

  & > svg {
    width: theme.$spacing-l;
    height: theme.$spacing-l;
  }
}

.market-header-title {
  font-size: theme.$fsize3;
}

.market-header-date-container {
  padding: theme.$spacing-s theme.$fsize-4;
  display: inline-block;
}

.market-header-date-picker-true {
  background-color: colors.$primary-50;
  border-radius: 4px;

  button {
    background-color: colors.$primary-50;
  }

  .dt-picker-calendar-icon-default path,
  .dt-picker-calendar-icon-edit rect {
    fill: colors.$primary-900;
  }

  .dt-picker-button {
    color: colors.$primary-900;
  }

  .market-header-date {
    color: colors.$neutral-800;
  }
}

.market-header-date {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: theme.$fsize-3;
  justify-content: flex-start;
  line-height: 1.33;

  p {
    height: 18px;
  }

  p > b,
  b {
    font-weight: 600;
    margin-left: 3px;
    margin-right: 3px;
  }
}

.market-header-warning-icon {
  height: 16px;
  margin-right: theme.$spacing-base;
  width: 16px;
}

.market-header-different-date-container {
  background-color: colors.$yellow-50;
  border-radius: 8px;
  border: solid 1px colors.$yellow-900;
  display: inline-block;
  padding: theme.$spacing-base;
}

.market-header-week-description {
  margin-top: 2px;
}

.market-header-different-date {
  align-items: center;
  color: colors.$neutral-800;
  display: flex;
  font-size: theme.$fsize-4;
  justify-content: flex-start;
  line-height: 1.33;
}

.market-header-remark-date {
  font-weight: 500;
}

.market-header-reset-button {
  background: none;
  border: none;
  margin-left: theme.$spacing-base;
  color: colors.$primary-900;
  font-weight: 600;
  font-size: theme.$fsize-4;
  text-decoration: underline;
}

.market-content {
  padding-top: theme.$spacing-xl;
}

@media screen and (max-width: 425px) {
  .market-header {
    .market-header-date {
      font-size: theme.$fsize-5;
    }
  }
  .market-header-different-date-container {
    padding: theme.$spacing-s theme.$spacing-base;
  }

  .market-header-warning-icon {
    display: none;
  }

  .market-header-different-date,
  .market-header-reset-button {
    font-size: theme.$fsize-4;
  }
  .market-header-different-date {
    text-align: justify;
    p {
      margin-left: 8px;
    }
  }
}

@media screen and (max-width: 320px) {
  .market-header {
    .market-header-date,
    .market-header-different-date,
    .market-header-reset-button {
      font-size: theme.$fsize-6;
    }
  }
}
