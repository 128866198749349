@use 'src/styles/theme';
@use 'src/styles/mixins/mq';

.grid-2-columns__fixed {
  display: grid;
  grid-gap: theme.$spacing-xl;
  grid-template-columns: 500px 1fr;
  grid-template-rows: 1fr;

  > * {
    min-width: 0;
  }

  .grid-fixed__left,
  .grid-fixed__right {
    grid-row: span 1;
  }

  .grid-fixed__left {
    overflow: auto;
    height: calc(100vh - #{theme.$spacing-l});

    @media (max-width: 1024px) {
      height: calc(100vh - (#{theme.$spacing-2xl} + #{theme.$spacing-5xl}));
    }
  }

  .grid-fixed__right {
    height: calc(100vh - #{theme.$spacing-2xl});

    @media (max-width: 1024px) {
      height: calc(100vh - (#{theme.$spacing-2xl} + #{theme.$spacing-5xl} + #{theme.$spacing-m}));
    }
  }

  @include mq.lt-sm {
    grid-template-columns: 1fr;
    grid-gap: 0;

    .grid-fixed__left,
    .grid-fixed__right {
      height: auto;
    }

    .grid-fixed__left {
      overflow: initial;
    }
  }
}
