@use 'src/styles/theme';
@use 'src/styles/colors';

.empty-program-list-container {
  align-items: center;
  background-color: colors.$white-90;
  border-radius: 16px;
  border: dashed 1px colors.$primary-300;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: theme.$spacing-m 0;
  padding: 37px 64px 40px;

  .empty-prog-list-description {
    color: colors.$neutral-800;
    font-size: theme.$fsize-2;
    font-weight: 600;
    line-height: 1.71;
    max-width: 300px;
    text-align: center;
  }

  .empty-program-icon {
    width: 90px;
    height: 90px;
  }
}
