@use 'src/styles/theme';
@use 'src/styles/colors';

.retail-container {
  padding: theme.$spacing-xl 0 theme.$spacing-base;
}

.retail-graph-container {
  position: relative;
}

.retail-graph-custom-yaxis-label {
  color: colors.$neutral-700;
  font-size: theme.$fsize-6;
  font-weight: 500;
  height: 24px;
  line-height: 2.4;
  margin-left: 10px;
}

.retail-header-title > h3 {
  color: colors.$neutral-800;
  font-size: theme.$fsize1;
  font-weight: 600;
  padding: theme.$spacing-base 0;
}

.retail-graph-header-description > p {
  color: colors.$neutral-700;
  font-size: theme.$fsize-2;
  height: 48px;
  line-height: 1.71;
  width: 683px;
  margin: theme.$spacing-base 0 theme.$spacing-l;
}

@media screen and (max-width: 1024px) {
  .retail-graph-header-description > p {
    width: 100%;
  }

  .retail-container {
    padding: theme.$spacing-xl 0;
  }
}
