@use 'src/styles/theme';
@use 'src/styles/colors';

.program-documentation-container {
  .prog-documentation-content {
    margin-top: theme.$spacing-xl;

    .prog-documentation-document-raw {
      padding: theme.$spacing-base 0;
      > h4 {
        color: colors.$neutral-700;
        font-size: theme.$fsize-3;
      }

      > hr {
        margin: 7px 0;
      }

      .prog-documentation-document-description {
        color: colors.$neutral-900;
        font-size: theme.$fsize-2;
        font-weight: 400;
        line-height: 1.71;
      }
    }
  }
}
