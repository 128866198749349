@use 'src/styles/theme';
@use 'src/styles/colors';

.error-container {
  background-color: colors.$red-800-20;
  border-radius: 16px;
  border: solid 1px colors.$red-900;
  max-width: 48%;
  min-height: 90px;
  padding: theme.$spacing-l;
}

.error-header {
  display: flex;
}

.error-header-icon {
  width: 24px;
  height: 24px;
  padding-right: theme.$spacing-base;
}

.error-header-title {
  color: colors.$neutral-900;
  font-size: theme.$fsize-base;
  font-weight: 600;
  line-height: 1.33;
}

.error-content {
  padding-top: theme.$spacing-base;
}

.error-content > p {
  color: colors.$neutral-800;
  font-size: theme.$fsize-1;
  height: 48px;
  line-height: 1.6;
  max-width: 570px;
}

@media screen and (max-width: 1024px) {
  .error-container {
    max-width: 90%;
  }
}

@media screen and (max-width: 425px) {
  .error-header {
    flex-direction: column;
    align-items: center;
  }
}
