@use 'src/styles/colors';
@use 'src/styles/theme';

// GENERAL BUTTON STYLES
.fk-button {
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  outline: none;
  text-align: center;
  -webkit-appearance: none;
}

// BUTTON TYPES
// MAIN
.button-primary-main {
  background-color: colors.$neutral-900;
  border-color: colors.$neutral-900;
  color: colors.$white-100;
}

.button-secondary-main {
  background-color: colors.$primary-800;
  border-color: colors.$primary-900;
  color: colors.$white-100;
}

.button-destructive-main {
  background-color: colors.$red-800;
  border-color: colors.$red-600;
  color: colors.$white-100;
}

.button-disabled-main {
  background-color: colors.$neutral-500;
  border-color: colors.$neutral-600;
  color: colors.$white-100;
  cursor: not-allowed;
}

// COMPLEMENTARY
.button-primary-complementary {
  background-color: rgba(0, 0, 0, 0);
  border-color: colors.$neutral-900;
  color: colors.$neutral-800;
}

.button-secondary-complementary {
  background-color: rgba(0, 0, 0, 0);
  border-color: colors.$primary-900;
  color: colors.$primary-900;
}

.button-destructive-complementary {
  background-color: colors.$white-100;
  border-color: colors.$red-900;
  color: colors.$red-900;
}

.button-disabled-complementary {
  background-color: colors.$neutral-300;
  border-color: colors.$neutral-400;
  color: colors.$neutral-600;
  cursor: not-allowed;
}

// BUTTON STATES
// += MAIN
// ++= HOVER
.button-primary-main:hover {
  background-color: colors.$neutral-700;
}

.button-secondary-main:hover {
  background-color: colors.$primary-600;
}

.button-destructive-main:hover {
  background-color: colors.$red-700;
}

// ++= PRESSED, ACTIVE
.button-primary-main:active {
  background-color: colors.$neutral-900;
}

.button-secondary-main:active {
  background-color: colors.$primary-900;
}

.button-destructive-main:active {
  background-color: colors.$red-900;
}

// ++= VISITED, FOCUS {ACTIVE}
// extra border hack same for all buttons types, focus don't works in safari
.fk-button:focus {
  -moz-box-shadow: 0 0 0 3px colors.$green-500-60;
  -webkit-box-shadow: 0 0 0 3px colors.$green-500-60;
  box-shadow: 0 0 0 3px colors.$green-500-60;
}

// the main button get same border color {REPLACE} when is focused styleguide RULES
.button-primary-main:focus,
.button-secondary-main:focus,
.button-destructive-main:focus {
  border-color: colors.$green-800-60;
}

.button-primary-main:focus {
  background-color: colors.$neutral-700;
}

.button-secondary-main:focus {
  background-color: colors.$primary-700;
}

.button-destructive-main:focus {
  background-color: colors.$red-700;
}

// += COMPLEMENTARY
// ++= HOVER
.button-primary-complementary:hover {
  background-color: colors.$secondary-800-10;
  border-color: colors.$secondary-900;
}

.button-secondary-complementary:hover {
  background-color: colors.$primary-800-10;
}

.button-destructive-complementary:hover {
  background-color: colors.$red-800-10;
}

// ++= FOCUS, PRESSED
.button-primary-complementary:active {
  background-color: colors.$secondary-500-20;
}

.button-secondary-complementary:active {
  background-color: colors.$primary-800-20;
}

.button-destructive-complementary:active {
  background-color: colors.$red-800-20;
}

// ++= VISITED, FOCUS {ACTIVE}
// extra border hack same for all buttons types, focus don't works in safari

.button-primary-complementary:focus {
  background-color: colors.$primary-800-10;
}

// BUTTON SIZES
.button-big {
  font-size: theme.$fsize-base;
  padding: theme.$spacing-m;
}

.button-medium {
  font-size: theme.$fsize-2;
  line-height: 1rem;
  padding: 15px theme.$spacing-m;
}

.button-small {
  font-size: theme.$fsize-4;
  padding: 10px theme.$spacing-m;
}

.button-x-small {
  font-size: theme.$fsize-4;
  padding: 8px theme.$spacing-m;
}
