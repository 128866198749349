@use 'src/styles/theme';
@use 'src/styles/colors';

.average-container {
  min-height: 425px;
}

.average-graph-container {
  position: relative;
}

.average-graph-custom-yaxis-label {
  color: colors.$neutral-700;
  font-size: theme.$fsize-3;
  font-weight: 500;
  height: 24px;
  line-height: 2.4;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-top: 10px;
  text-transform: uppercase;
}

.average-graph-custom-xaxis-month-label {
  font-size: theme.$fsize-5;
  font-weight: 500;
  line-height: 2.4;
  color: colors.$neutral-700;
}

.xaxis-month-left {
  bottom: 47px;
  left: 54px;
  position: absolute;
}

.xaxis-month-right {
  bottom: 47px;
  right: 10px;
  position: absolute;
}

.average-graph-description {
  border-radius: 16px;
  border: solid 1px colors.$primary-100;
  margin: theme.$spacing-m 0;
  padding: theme.$spacing-m;
  width: 53%;

  .average-graph-description-text {
    color: colors.$neutral-900;
    font-size: theme.$fsize-3;
  }
}

@media screen and (max-width: 1024px) {
  .average-divisor-responsive {
    display: none;
  }
  .average-graph-description {
    max-width: 684px;
    width: 94%;
  }
}
