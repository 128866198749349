@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/mixins/mq';

.opportunity-card-wrapper {
  user-select: none;
  cursor: pointer;
  position: relative;
  display: flex;
  margin: theme.$spacing-l 0;

  // content
  // content container
  .opportunity-card-content-container {
    border: solid 1px colors.$primary-100;
    box-shadow: 0 16px 16px -24px colors.$neutral-800-30;
    border-radius: 16px;

    &.normal-program {
      background-color: colors.$primary-50;

      &.opportunity-assigned-true {
        background-color: colors.$primary-200;
      }
    }

    &.spot-program {
      background-color: colors.$green-50;

      &.opportunity-assigned-true {
        background-color: colors.$green-900;
      }
    }

    &.expired-program {
      border: solid 1px colors.$neutral-400;
      background-color: colors.$neutral-400;

      &.opportunity-assigned-true {
        background-color: colors.$neutral-800;
      }
    }

    // flex styles on containers
    .opportunity-card-content-footer-data-inner-row,
    .opportunity-card-content-header-data-inner-row,
    .opportunity-card-content-header-info,
    .opportunity-card-content-header-info-container,
    .oppotunity-card-content-header-opportunity-commodity-info-wrapper,
    .opportunity-card-content-header-time-info-inner-row {
      display: flex;
      align-items: center;

      @include mq.lt-sm {
        flex-wrap: wrap;
      }
    }

    // content footer
    .opportunity-card-content-footer {
      grid-gap: theme.$spacing-xl;
      padding: theme.$spacing-base theme.$spacing-xl;

      @media (max-width: 1024px) {
        padding: theme.$spacing-base 10px;
      }

      .opportunity-card-content-footer-data-container {
        .opportunity-card-content-footer-data-label {
          color: colors.$neutral-800;
          font-size: theme.$fsize-6;
          font-weight: 700;
          line-height: 1.6;
        }

        .opportunity-card-content-footer-data-inner-row {
          .opportunity-card-footer-data-icon {
            height: 16px;
            margin-right: theme.$spacing-s;
            width: 16px;
          }

          .opportunity-card-content-footer-data-value {
            color: colors.$neutral-900;
            font-size: theme.$fsize-2;
            font-weight: 800;
            line-height: 1.71;
          }
        }
      }
    }
  }
  // end content container

  // content header
  .opportunity-card-content-header {
    position: relative;
    padding: theme.$spacing-m;
    border-radius: 16px;

    @media (max-width: 1024px) {
      padding: theme.$spacing-m theme.$spacing-base;
    }

    // program types
    &.normal-program,
    &.spot-program {
      background-color: colors.$white-100;
    }

    &.normal-program {
      &.opportunity-assigned-true {
        background-color: colors.$primary-50;
      }
    }

    &.spot-program {
      &.opportunity-assigned-true {
        background-color: colors.$green-300;
      }
    }

    &.expired-program {
      background-color: colors.$neutral-50;
    }

    // header info
    .opportunity-card-content-header-info-container {
      padding: 0 0 theme.$spacing-m theme.$spacing-base;

      @media (max-width: 1024px) {
        padding-bottom: theme.$spacing-base;
      }

      .opportunity-card-content-header-info {
        .opportunity-card-content-header-img-container {
          align-items: center;
          border-radius: 16px;
          box-shadow: 0 2px 4px 0 rgba(49, 50, 51, 0.2);
          display: flex;
          height: 28px;
          justify-content: center;
          width: 28px;
          border: 2px solid colors.$white-100;

          .opportunity-card-content-header-img {
            border-radius: inherit;
            height: 100%;
            width: 100%;
          }
        }

        .oppotunity-card-content-header-opportunity-commodity-info-wrapper {
          margin-left: theme.$spacing-base;
          font-size: theme.$fsize-6;
          color: colors.$neutral-900;
          font-weight: bold;

          @media (max-width: 1024px) {
            display: initial;
          }
        }

        .opportunity-card-content-header-opportunity-id {
          font-size: theme.$fsize-6;
          font-weight: 400;
          line-height: 1.6;
        }
      }

      // program status
      .opportunity-card-content-header-opportunity-status-container {
        background-color: colors.$primary-200;
        border-radius: 12px;
        padding: theme.$spacing-s theme.$spacing-base;

        .opportunity-card-content-header-opportunity-status {
          color: colors.$neutral-900;
          font-size: theme.$fsize-6;
          font-weight: 600;
          line-height: 1.6;
        }
      }
    }

    // timer info
    .opportunity-card-content-header-time-info {
      background-color: colors.$green-800;
      border-bottom-left-radius: inherit;
      border-color: colors.$green-900;
      border-style: solid;
      border-top-right-radius: inherit;
      border-width: 1px;
      padding: 7px 16px 8px;
      position: absolute;
      right: 0;
      top: 0;

      .opportunity-card-content-header-time-info-label {
        color: colors.$neutral-50;
        font-size: theme.$fsize-6;
        line-height: 1.6;
      }

      .opportunity-card-content-header-time-info-inner-row {
        .opportunity-card-content-header-time-value {
          margin-left: theme.$spacing-s;
          color: colors.$white-100;
          font-size: theme.$fsize-2;
          font-weight: 600;
          line-height: 1.71;
        }
        .opportunity-card-content-header-time-info-icon {
          height: 16px;
          width: 16px;

          #timer-icon-3 {
            fill: colors.$white-100;
          }
        }
      }

      &.expired {
        right: 15px;
        top: 15px;
        border-radius: 20px;
        padding: 5px 12px;
        font-size: 10px;
        background-color: colors.$yellow-300;
        border: none;
      }
    }

    // header data
    .opportunity-card-content-header-data-row {
      grid-gap: theme.$spacing-base;

      .opportunity-card-content-header-data-container {
        .opportunity-card-content-header-data-label {
          color: colors.$neutral-800;
          font-size: theme.$fsize-6;
          font-weight: 700;
          line-height: 1.6;
        }
      }

      .opportunity-card-content-header-data-inner-row {
        .opportunity-card-content-header-data-unit-value {
          color: colors.$neutral-900;
          font-size: 26px;
          font-weight: 700;
          line-height: 1.23;

          &.price-label {
            &:before {
              font-size: theme.$fsize-2;
              font-weight: 500;
              margin-right: theme.$spacing-s;

              @media (max-width: 1024px) {
                content: initial;
              }
            }
          }
        }

        .opportunity-card-content-header-data-unit-icon {
          width: theme.$spacing-m;
          height: theme.$spacing-m;
          margin: theme.$spacing-s theme.$spacing-s 0;
          flex-shrink: 0;

          &.truck {
            width: 24px;
            height: 24px;
            margin-top: 9px;
          }

          &.box {
            width: 20px;
            height: 20px;
            margin-top: 9px;
          }
        }

        .opportunity-card-content-header-data-unit-label {
          color: colors.$neutral-700;
          font-size: theme.$fsize-2;
          font-weight: 500;
          line-height: 1.71;
          align-self: flex-end;
          text-transform: capitalize;

          @media (max-width: 1024px) {
            font-size: theme.$fsize-4;
          }

          &.middle-label {
            margin-left: theme.$spacing-s;
          }

          &.last-label {
            margin-left: theme.$spacing-s;
          }
        }
      }

      .opportunity-card-content-header-data-unit-label-help-text {
        color: colors.$neutral-900;
        font-size: theme.$fsize-5;
        line-height: 1.45;

        &.label-hidden {
          color: white;
        }
      }
    }
  }
  // end content header

  // select states
  &.opportunity-assigned-true {
    .opportunity-card-content-container {
      &.spot-program {
        border-color: colors.$green-300;
      }
    }
    .opportunity-card-content-header {
      // normal program
      &.normal-program {
        .opportunity-card-content-header-data-unit-label-help-text {
          &.label-hidden {
            color: colors.$primary-50;
          }
        }
      }
    }

    &.spot-program {
      .opportunity-card-content-footer {
        .opportunity-card-content-footer-data-container {
          .opportunity-card-content-footer-data-inner-row {
            .opportunity-card-footer-data-icon {
              #money-location-icon,
              #Combined-Shape-calendar {
                fill: colors.$white-100;
              }
            }

            .opportunity-card-content-footer-data-value {
              color: colors.$white-100;
            }
          }
        }
        &.spot-program {
          .opportunity-card-content-footer-data-label {
            color: colors.$green-200;
          }
        }
        &.normal-program {
          .opportunity-card-content-footer-data-label {
            color: colors.$primary-300;
          }
        }
      }
    }
  }

  &.opportunity-selected-true {
    // header
    .opportunity-card-content-header {
      // normal program
      &.normal-program {
        background-color: colors.$primary-700;
        .opportunity-card-content-header-data-label,
        .opportunity-card-content-header-data-unit-label,
        .opportunity-card-content-header-data-unit-label-help-text {
          color: colors.$primary-300;
        }

        .opportunity-card-content-header-data-unit-label-help-text {
          &.label-hidden {
            color: colors.$primary-700;
          }
        }

        .opportunity-card-content-header-data-unit-value {
          &.price-label {
            &:before {
              color: colors.$primary-200;
            }
          }
        }
      }
      // end normal program

      // spot program
      &.spot-program {
        background-color: colors.$green-800;
        .opportunity-card-content-header-data-label,
        .opportunity-card-content-header-data-unit-label,
        .opportunity-card-content-header-data-unit-label-help-text,
        .opportunity-card-content-footer-data-label {
          color: colors.$green-200;
        }

        .opportunity-card-content-header-data-unit-value {
          &.price-label {
            &:before {
              color: colors.$green-200;
            }
          }
        }
      }

      // end spot program

      .opportunity-card-content-header-time-info {
        background-color: colors.$red-800;
        border-color: colors.$red-800;
        &.expired {
          background-color: colors.$yellow-300;
        }
      }

      .opportunity-card-content-header-info {
        .oppotunity-card-content-header-opportunity-commodity-info-wrapper {
          color: colors.$white-80;
        }
      }

      .opportunity-card-content-header-data-row {
        .opportunity-card-content-header-data-inner-row {
          .opportunity-card-content-header-data-unit-value {
            color: colors.$white-100;
          }

          .opportunity-card-content-header-data-unit-icon {
            // pallet icon
            #icon-pallet-symbol {
              g,
              path,
              rect {
                fill: colors.$white-100;
              }
            }
            #Shape-package,
            #truck-icon-to-fill,
            #Shape-lb-icon,
            #Shape-kg-icon {
              fill: colors.$white-100;
            }
          }
        }
      }

      &.expired-program {
        background-color: colors.$neutral-300;
        .opportunity-card-content-header-info {
          .opportunity-card-content-header-opportunity-id {
            color: colors.$neutral-600;
          }
        }
        .opportunity-card-content-header-data-row {
          .opportunity-card-content-header-data-inner-row {
            .opportunity-card-content-header-data-unit-value,
            .opportunity-card-content-header-data-unit-icon {
              color: colors.$neutral-600;
              #icon-pallet-symbol {
                g,
                path,
                rect {
                  fill: colors.$neutral-600;
                }
              }
              #Shape-package,
              #truck-icon-to-fill,
              #Shape-lb-icon,
              #Shape-kg-icon {
                fill: colors.$neutral-600;
              }
            }
          }
        }
      }
    }
    // end header states

    //content
    .opportunity-card-content-container {
      &.normal-program {
        background-color: colors.$primary-800;
        border-color: colors.$primary-800;
      }

      &.spot-program {
        border-color: colors.$green-900;
        background-color: colors.$green-900;
      }
      &.expired-program {
        border-color: colors.$neutral-800;
        background-color: colors.$neutral-800;
      }

      // footer
      .opportunity-card-content-footer {
        .opportunity-card-content-footer-data-container {
          .opportunity-card-content-footer-data-inner-row {
            .opportunity-card-footer-data-icon {
              #money-location-icon,
              #Combined-Shape-calendar {
                fill: colors.$white-100;
              }
            }

            .opportunity-card-content-footer-data-value {
              color: colors.$white-100;
            }
          }
        }
        &.spot-program {
          .opportunity-card-content-footer-data-label {
            color: colors.$green-200;
          }
        }
        &.normal-program {
          .opportunity-card-content-footer-data-label {
            color: colors.$primary-300;
          }
        }
        &.expired-program {
          background-color: colors.$neutral-800;
          .opportunity-card-content-footer-data-label,
          .opportunity-card-content-footer-data-inner-row .opportunity-card-content-footer-data-value,
          .opportunity-card-content-footer-data-icon,
          .opportunity-card-content-footer-data-inner-row .opportunity-card-footer-data-icon {
            color: colors.$neutral-600;
            #money-location-icon,
            #Combined-Shape-calendar {
              fill: colors.$neutral-600;
            }
          }
        }
      }
    }
  }
  // end select states
}

.opportunity-card-flag-type {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: theme.$spacing-base + 1;
  width: 96px;
  height: theme.$spacing-2xl;
  text-align: center;
  transform-origin: 50% 100%;
  transform: rotate(270deg);
  border-radius: theme.$spacing-m theme.$spacing-m 0 0;
  box-shadow: 0 16px 16px -24px rgba(49, 50, 51, 0.3);

  &.normal-program {
    background-color: colors.$primary-800;
  }

  &.spot-program {
    background-color: colors.$green-900;
  }

  &.expired-program {
    background-color: colors.$neutral-800;
  }

  .opportunity-card-flag-type-label {
    color: colors.$white-100;
    font-size: theme.$fsize-5;
    font-weight: 600;
    line-height: 1.45;
    text-transform: uppercase;
  }
}

.opportunity-card-content-container {
  width: 100%;
  margin: 0 0 0 theme.$spacing-xl;
}

@media (max-width: 1024px) {
  .opportunity-card-content-container {
    margin: theme.$spacing-xl 0 0 0;
  }

  .opportunity-card-flag-type {
    transform: none;
  }
}
