@use 'src/styles/theme';
@use 'src/styles/colors';

.usda-prices-container {
  padding-top: theme.$spacing-m;
}

.usda-prices-header-wrapper {
  display: flex;
  .usda-prices-header-title {
    color: colors.$neutral-800;
    font-size: theme.$fsize1;
    font-weight: 600;
    padding: theme.$spacing-base 0;
    text-transform: capitalize;
  }
  .usda-prices-header-beta {
    background-color: colors.$primary-200;
    border-radius: 12px;
    color: colors.$neutral-900;
    font-size: 10px;
    font-weight: 700;
    line-height: 1.6;
    margin-left: 10px;
    max-height: 25px;
    padding: 4px 8px;
    text-align: center;
  }

  .usda-prices-header-title > h3 {
    color: colors.$neutral-800;
    font-size: theme.$fsize1;
    font-weight: 600;
    padding: theme.$spacing-base 0;
  }

  .usda-prices-header-description > p {
    color: colors.$neutral-700;
    font-size: theme.$fsize-2;
    height: 48px;
    line-height: 1.71;
    width: 683px;
  }

  .usda-prices-description {
    color: colors.$neutral-700;
    font-size: theme.$fsize-2;
    line-height: 1.71;
  }

  .usda-prices-buttons {
    display: flex;
    margin-top: theme.$spacing-l;
  }

  button.usda-category-button {
    background-color: colors.$white-100;
    border-color: colors.$primary-900;
    border-radius: 0px;
    color: colors.$primary-800;
    font-size: theme.$fsize-3;
    padding: theme.$spacing-base theme.$spacing-l;
  }

  button.usda-first-button {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  button.usda-last-button {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  button.usda-category-button-selected {
    background-color: colors.$primary-800;
    color: colors.$white-100;
  }

  @media screen and (max-width: 1024px) {
    .usda-prices-container {
      padding: 0;
    }

    .usda-prices-buttons button {
      &:focus,
      &:active {
        box-shadow: none;
      }
    }
  }
}
