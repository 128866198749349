/* colors */

$checkbox-button: #0700cc;
$green-100: #e2ecd7;
$green-200: #d4e3c4;
$green-300: #c5dab0;
$green-400: #b7d19c;
$green-500-60: rgba(148, 223, 115, 0.6);
$green-500: #a8c788;
$green-50: #f1f6eb;
$green-600: #9abe74;
$green-700: #8bb561;
$green-800-10: rgba(125, 171, 77, 0.1);
$green-800-20: rgba(125, 171, 77, 0.2);
$green-800-30: rgba(125, 171, 77, 0.3);
$green-800-40: rgba(125, 171, 77, 0.4);
$green-800-50: rgba(125, 171, 77, 0.5);
$green-800-60: rgba(125, 171, 77, 0.6);
$green-800-70: rgba(125, 171, 77, 0.7);
$green-800-80: rgba(125, 171, 77, 0.8);
$green-800-90: rgba(125, 171, 77, 0.9);
$green-800: #7dab4d;
$green-900: #6ea239;
$neutral-100: #f0f1f2;
$neutral-200: #e8e9eb;
$neutral-300: #dcdee0;
$neutral-400: #c4c7cc;
$neutral-500: #9d9fa3;
$neutral-50: #f7f8fa;
$neutral-600: #76777a;
$neutral-700: #4e5052;
$neutral-800-10: rgba(49, 50, 51, 0.1);
$neutral-800-20: rgba(49, 50, 51, 0.2);
$neutral-800-30: rgba(49, 50, 51, 0.3);
$neutral-800-40: rgba(49, 50, 51, 0.4);
$neutral-800-50: rgba(49, 50, 51, 0.5);
$neutral-800-60: rgba(49, 50, 51, 0.6);
$neutral-800-70: rgba(49, 50, 51, 0.7);
$neutral-800-80: rgba(49, 50, 51, 0.8);
$neutral-800-90: rgba(49, 50, 51, 0.9);
$neutral-800-96: rgba(49, 50, 51, 0.96);
$neutral-800: #313233;
$neutral-900: #141414;
$primary-100: #d0cff6;
$primary-200: #b8b6f1;
$primary-300: #a09eed;
$primary-400: #8986e8;
$primary-500: #716ee3;
$primary-50: #e7e7fa;
$primary-600-90: rgba(42, 37, 214, 0.9);
$primary-600: #5956df;
$primary-700: #413dda;
$primary-800-10: rgba(42, 37, 214, 0.1);
$primary-800-20: rgba(42, 37, 214, 0.2);
$primary-800-30: rgba(42, 37, 214, 0.3);
$primary-800-40: rgba(42, 37, 214, 0.4);
$primary-800-50: rgba(42, 37, 214, 0.5);
$primary-800-60: rgba(42, 37, 214, 0.6);
$primary-800-70: rgba(42, 37, 214, 0.7);
$primary-800-80: rgba(42, 37, 214, 0.8);
$primary-800: #2a25d6;
$primary-900: #120dd1;
$red-100: #facfd5;
$red-200: #f7b6bf;
$red-300: #f49eaa;
$red-400: #f28695;
$red-500: #ef6e80;
$red-50: #fce7ea;
$red-600: #ec566b;
$red-700: #e93d55;
$red-800-10: rgba(231, 37, 64, 0.1);
$red-800-20: rgba(231, 37, 64, 0.2);
$red-800-30: rgba(231, 37, 64, 0.3);
$red-800-40: rgba(231, 37, 64, 0.4);
$red-800-50: rgba(231, 37, 64, 0.5);
$red-800-60: rgba(231, 37, 64, 0.6);
$red-800-70: rgba(231, 37, 64, 0.7);
$red-800-80: rgba(231, 37, 64, 0.8);
$red-800-90: rgba(231, 37, 64, 0.9);
$red-800: #e72540;
$red-900: #e40d2b;
$secondary-100: #d1ecf1;
$secondary-200: #bbe3e9;
$secondary-300: #a4d9e2;
$secondary-400: #8dd0db;
$secondary-500-20: rgba(50, 170, 190, 0.2);
$secondary-500: #76c6d4;
$secondary-50: #e8f6f8;
$secondary-600: #5fbdcd;
$secondary-700: #49b3c5;
$secondary-800-10: rgba(50, 170, 190, 0.1);
$secondary-800-30: rgba(50, 170, 190, 0.3);
$secondary-800-40: rgba(50, 170, 190, 0.4);
$secondary-800-50: rgba(50, 170, 190, 0.5);
$secondary-800-60: rgba(50, 170, 190, 0.6);
$secondary-800-70: rgba(50, 170, 190, 0.7);
$secondary-800-80: rgba(50, 170, 190, 0.8);
$secondary-800-90: rgba(50, 170, 190, 0.9);
$secondary-800: #32aabe;
$secondary-900: #1ba0b7;
$white-010: rgba(255, 255, 255, 0.1);
$white-100: #ffffff;
$white-20: rgba(255, 255, 255, 0.2);
$white-30: rgba(255, 255, 255, 0.3);
$white-40: rgba(255, 255, 255, 0.4);
$white-50: rgba(255, 255, 255, 0.5);
$white-5: rgba(255, 255, 255, 0.05);
$white-60: rgba(255, 255, 255, 0.6);
$white-70: rgba(255, 255, 255, 0.7);
$white-80: rgba(255, 255, 255, 0.8);
$white-90: rgba(255, 255, 255, 0.9);
$yellow-100: #fef4d1;
$yellow-200: #fdefb9;
$yellow-300: #fde9a2;
$yellow-400: #fce48b;
$yellow-500: #fbdf74;
$yellow-50: #fefae8;
$yellow-600: #fbd95d;
$yellow-700: #fad445;
$yellow-800: #f9c917;
$yellow-900: #e0b515;
$progress-bar-yellow: rgba(249, 201, 23, 0.9);
