@use 'src/styles/theme';
@use 'src/styles/colors';

.fk-checkbox-container {
  align-items: center;
  display: flex;
  height: 100%;
  margin-left: theme.$spacing-m;
  outline: none;

  .fk-checkbox {
    align-items: center;
    border-radius: 4px;
    border: 1px solid colors.$neutral-500;
    display: grid;
    grid-template-columns: 100%;
    height: 16px;
    justify-items: center;
    width: 16px;

    &.checkbox-true {
      background-color: colors.$primary-900;
      border-color: colors.$primary-900;
    }

    .fk-checkbox-check-icon {
      display: none;
      grid-column-end: 1;
      grid-column-start: 1;
      grid-row-end: 1;
      grid-row-start: 1;
      height: 11px;
      width: 11px;

      g {
        fill: colors.$white-100;
      }
    }

    .fk-checkbox-input {
      opacity: 0;
      grid-column-end: 1;
      grid-column-start: 1;
      grid-row-end: 1;
      grid-row-start: 1;

      &:checked + .fk-checkbox-check-icon {
        display: inline;
      }
    }
  }

  label {
    height: 100%;
    padding: theme.$spacing-m theme.$spacing-s theme.$spacing-m theme.$spacing-m;
    position: relative;
    width: 100%;
    color: colors.$neutral-900;
    cursor: pointer;
    font-size: theme.$fsize-4;
    font-weight: 400;
    line-height: 1.33;

    &:after {
      content: '';
      height: 100%;
      left: -16px;
      position: absolute;
      top: 0;
      width: 113%;
    }
  }
}
