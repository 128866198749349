@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/mixins/mq';

.commit-resume-container {
  border-radius: 16px;
  position: relative;
  z-index: 1;

  .commit-resume-header {
    padding: theme.$spacing-l theme.$spacing-xl theme.$spacing-m theme.$spacing-xl;
    .commit-resume-title {
      color: colors.$neutral-900;
      font-size: theme.$fsize-base;
      font-weight: 600;
      line-height: 1.5;
    }
  }
  .commit-resume-content {
    background-color: colors.$primary-100;

    .commit-resume-content-inner {
      align-items: center;
      background-color: colors.$primary-50;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 theme.$spacing-xl theme.$spacing-xl theme.$spacing-xl;
      overflow: hidden;

      @include mq.lt-sm {
        display: grid;
        grid-gap: theme.$spacing-s;
        grid-template-columns: repeat(3, 1fr);
        padding: 0 theme.$spacing-l theme.$spacing-l theme.$spacing-l;
      }

      .commit-resume-content-inner-data {
        h6 {
          color: colors.$neutral-800;
          font-size: theme.$fsize-6;
          font-weight: 600;
          line-height: 1.6;
        }

        .commit-resume-inner-data-row {
          align-items: center;
          display: flex;
          margin-top: theme.$spacing-base;

          .commit-resume-content-total-quantity,
          .commit-resume-content-total-currency,
          .commit-resume-content-unit-description-label {
            color: colors.$neutral-900;
            font-size: theme.$fsize-6;
            line-height: 1.6;
          }

          .commit-resume-content-unit-description-label {
            margin-top: 4px;
          }

          .commit-resume-content-unit-label,
          .commit-resume-content-total-week,
          .commit-resume-content-estimated-kg,
          .commit-resume-content-total-return {
            color: colors.$neutral-900;
            font-size: theme.$fsize-base;
            font-weight: 600;
            line-height: 1.5;
          }

          .commit-resume-content-unit-label {
            margin: 0 theme.$spacing-s;
          }

          .commit-resume-content-total-quantity {
            margin-top: 3px;
            margin-left: theme.$spacing-s;
          }

          .commit-resume-content-estimated-kg,
          .commit-resume-content-total-return {
            &:before {
              color: colors.$neutral-700;
              font-size: theme.$fsize-2;
              font-weight: 500;
              line-height: 1.71;
              margin-right: 3px;
            }
          }

          .commit-resume-content-total-currency {
            margin-left: 4px;
            margin-top: 3px;
          }

          .commit-resume-content-unit-icon {
            height: 16px;
            width: 16px;

            &.box-icon {
              height: 18px;
              width: 18px;
              margin-top: 3px;
            }

            &.truck-icon {
              height: 21px;
              width: 21px;
            }

            &.kg-icon,
            &.lb-icon {
              margin-bottom: 3px;
            }
          }
        }
      }
    }
  }

  .commit-resume-footer {
    background-color: colors.$primary-100;
    border-radius: 16px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: theme.$spacing-m theme.$spacing-xl;

    .comment-resume-buttons-row {
      .commit-resume-detail-commit-buttons,
      .commit-resume-box-program-buttons {
        display: flex;
      }

      .commit-resume-box-program-buttons {
        justify-content: flex-end;
      }

      .commit-resume-detail-commit-buttons,
      .commit-resume-confirm-commit-buttons {
        justify-content: space-between;
      }
    }
  }
}
