@use 'src/styles/theme';
@use 'src/styles/colors';

.welcome-message {
  background-color: colors.$neutral-50;
  border-radius: 16px;
  margin-bottom: theme.$spacing-l;
  padding: 40px 32px 40px 31px;

  .welcome-message-label {
    color: colors.$neutral-900;
    font-size: theme.$fsize-2;
    line-height: 1.71;
    margin: theme.$spacing-m 0;
  }

  .welcome-acept-button {
    background-color: colors.$neutral-900;
    color: colors.$white-100;
    padding: 8px 16px;
    width: 100px;
  }
}
