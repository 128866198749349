@use 'src/styles/theme';
@use 'src/styles/colors';

.weekly-locations-graph-container {
  min-height: 425px;
}

.wkly-lcs-graph-inner-container {
  padding: theme.$spacing-base 0;
  position: relative;

  .wkly-lcs-graph-yaxis-label {
    color: colors.$neutral-900;
    font-size: theme.$fsize-6;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 5px;
    margin-left: 10px;
  }
}

.wkly-lcs-graph-highcharts-ylabel {
  color: colors.$neutral-900;
  font-size: theme.$fsize-6;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 5px;
  margin-left: 10px;
}

.wkly-lcs-graph-description {
  color: colors.$neutral-700;
  font-size: theme.$fsize-4;
  margin: theme.$spacing-l 0;
  max-width: 683px;
}

.wkly-lcs-filter-container {
  margin-bottom: theme.$spacing-m;
}

@media screen and (max-width: 1024px) {
  .wkly-lcs-graph-description {
    width: 100%;
  }
}
