@use 'src/styles/theme';
@use 'src/styles/colors';

.sider-container {
  position: sticky;
  top: theme.$spacing-l;
  bottom: theme.$spacing-l;
  border: 1px solid colors.$primary-100;
  padding: 1px theme.$spacing-m 1px 1px;
  height: calc(100vh - #{theme.$spacing-l} * 2);

  background-color: colors.$neutral-50;
  border-radius: theme.$spacing-m;
  box-shadow: 0 16px 16px -24px rgba(49, 50, 51, 0.3);

  .sider-inner {
    background-color: colors.$primary-900;
    display: grid;
    grid-template-rows: 80px auto;
    width: 100%;
    height: 100%;
    border-radius: theme.$spacing-m;

    .sider-header-container {
      .sider-header-logo-container {
        margin-top: theme.$spacing-2xl;
        text-align: center;

        .sider-header-logo {
          height: 15px;
          width: 40px;
          g {
            fill: colors.$white-100;
          }
        }
      }
    }
  }

  .sider-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and(max-width: 1024px) {
  .sider-container {
    padding: theme.$spacing-base 1px 1px 1px;
    height: fit-content;
    box-shadow: 0 -16px 23px -24px rgba(20, 20, 20, 0.6);

    .sider-header-container {
      display: none;
    }

    .sider-inner {
      height: fit-content;
      top: 0;
      grid-template-rows: 1fr;
    }
  }
}
