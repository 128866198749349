@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/mixins/mq';

.fk-input-container {
  width: inherit;

  input {
    height: 38px;
    line-height: 1.14;
    outline: none;
    padding: 0 theme.$spacing-m;
    width: 100%;

    &::placeholder {
      color: colors.$neutral-500;
    }

    @include mq.lt-md {
      font-size: theme.$fsize-base;
      height: 46px;
    }
  }

  label {
    display: inherit;
  }

  &.submit-error-true {
    input {
      border-color: colors.$red-900;
    }
  }
}

.fk-input__input-base {
  border: solid 1px colors.$neutral-300;
  border-radius: theme.$spacing-base;
  box-shadow: inset 0 1px 2px 0 colors.$neutral-800-10;

  &:hover {
    border-color: colors.$primary-500;
    box-shadow: inset 0 1px 2px 0 colors.$neutral-800-10;
  }

  &:focus-within {
    box-shadow: 0 0 0 3px colors.$green-800-60;
  }
}

p.fk-input-label {
  line-height: 1.33;

  @include mq.lt-md {
    font-size: theme.$fsize-base;
  }
}
