@use 'src/styles/theme';
@use 'src/styles/colors';

.confirm-commitment-container {
  .cnfirm-cmtmnt-content {
    margin-top: theme.$spacing-xl;

    // resume
    .cnfirm-cmtmnt-cntnt-resume {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .cnfirm-cmtmnt-cntnt-resume-row-data {
        .resume-rw-dta-label {
          color: colors.$neutral-900;
          font-size: theme.$fsize-4;
          line-height: 1.33;
        }

        .resume-rw-dta-inner-row {
          align-items: center;
          display: flex;
          margin-top: theme.$spacing-base;

          .resume-rw-dta-unit-icon {
            height: 19px;
            width: 19px;
          }

          .resume-rw-dta-value {
            color: colors.$neutral-900;
            font-size: theme.$fsize-base;
            font-weight: 700;
            line-height: 1.5;
            margin: 0 theme.$spacing-base;
          }

          .resume-rw-dta-value-description {
            color: colors.$neutral-900;
            font-size: theme.$fsize-6;
            font-weight: 400;
            line-height: 1.5;
            margin-top: 3px;
          }
        }

        display: flex;
        flex-direction: column;
      }
    }

    // confirm
    .cnfirm-cmtmnt-cntnt-confirm {
      background-color: colors.$neutral-50;
      border-radius: 16px;
      margin-top: theme.$spacing-l;
      padding: theme.$spacing-xl;

      .cntnt-confirm-description {
        margin-top: theme.$spacing-l;
        display: flex;

        .cntnt-confirm-description-label {
          color: colors.$neutral-900;
          font-size: theme.$fsize-2;
          line-height: 1.71;

          b,
          a {
            font-weight: 700;
          }

          a {
            color: colors.$primary-900;
            margin: 0 5px;
            text-decoration: none;
          }
        }

        .fk-checkbox-container {
          margin: 3px theme.$spacing-base 0 0;
          label {
            padding: 0;
          }
        }

        .fk-checkbox {
          &.error-true {
            border-color: colors.$red-900;
          }
        }
      }

      .cntnt-confirm-footer-note {
        color: colors.$neutral-900;
        font-size: theme.$fsize-4;
        line-height: 1.33;
        margin-top: theme.$spacing-xl;
      }

      .cnfirm-cmtmnt-content-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: theme.$spacing-xl;
      }

      .cnfirm-cmtmnt-render-to-confirm {
        background-color: colors.$red-50;
        border-radius: 16px;
        margin-top: theme.$spacing-m;
        padding: 16px 46px 16px 16px;
        position: relative;

        p {
          color: colors.$neutral-900;
          font-size: theme.$fsize-4;
          font-weight: 400;
          line-height: 1.33;
        }
      }
      .cnfirm-cmtmnt-confirm-button {
        position: absolute;
        bottom: 15px;
        right: 30px;
        z-index: 2;
      }
    }

    h4 {
      color: colors.$neutral-700;
      font-size: theme.$fsize-3;
      font-weight: 700;
      line-height: 1.23;
    }

    hr {
      margin: theme.$spacing-base 0;
    }
  }
}
