@use 'src/styles/theme';
@use 'src/styles/colors';

.select-checkbox-container {
  position: relative;
  .select-button-arrow-icon {
    width: 11px;
    height: 11px;

    path {
      fill: colors.$neutral-900;
    }
  }

  [class^='select-checkbox-button-text-'] {
    color: colors.$neutral-900;
    font-size: theme.$fsize-2;
    font-weight: 400;
    line-height: 1.71;
    margin-left: 5px;
    margin-right: 10px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
  }

  [class^='select-checkbox-button-component'] {
    align-items: center;
    background-color: colors.$white-100;
    border-radius: theme.$spacing-base;
    border: solid 1px colors.$neutral-300;
    display: flex;
    font-size: theme.$fsize-2;
    font-weight: 400;
    line-height: 1.14;
    outline: none;
    padding: theme.$spacing-base theme.$spacing-m;
    text-align: center;
    height: theme.$select-height;

    &:hover {
      cursor: pointer;
      border-color: colors.$primary-500;
    }

    &:active {
      border-color: colors.$primary-700;
    }

    &:focus {
      border-color: colors.$primary-500;
      box-shadow: 0 0 0 3px colors.$green-800-60;
    }

    &.disabled-true {
      background-color: colors.$neutral-100;

      &:hover {
        border-color: colors.$neutral-500;
        cursor: not-allowed;
      }
    }
  }

  [class^='select-checkbox-floating-menu-'] {
    background-color: colors.$white-100;
    border-radius: theme.$spacing-base;
    border: solid 1px colors.$neutral-300;
    box-shadow: 0 8px 8px -4px colors.$primary-800-10;
    left: 0;
    position: absolute;
    top: 48px;
    min-width: 155px;
    width: 100%;
    z-index: 100;

    [class^='select-checkbox-floating-options-'] {
      border-radius: inherit;
      list-style-type: none;

      li {
        border-top-color: colors.$neutral-200;
        border-top-style: solid;
        border-top-width: 1px;
        color: colors.$neutral-900;
        font-size: theme.$fsize-2;
        font-weight: 500;
        line-height: 1.33;

        &:hover {
          background-color: colors.$primary-50;
          color: colors.$primary-800;
          cursor: pointer;
        }
      }

      li:first-child {
        border-top: 0 !important;

        &:hover {
          border-top-left-radius: inherit;
          border-top-right-radius: inherit;
        }
      }

      li:last-child {
        &:hover {
          border-bottom-left-radius: inherit;
          border-bottom-right-radius: inherit;
        }
      }
    }
  }
}
