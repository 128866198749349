@use 'src/styles/theme';
@use 'src/styles/colors';

.volume-table-container {
  margin-bottom: 10px;
  padding: theme.$spacing-xl 0;

  hr {
    margin: theme.$spacing-m 0;
  }
}

.vl-table-inner-container {
  background-color: colors.$primary-800-10;
  padding: theme.$spacing-l theme.$spacing-xl;
}

.vl-table-content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 53px;

  thead {
    g {
      fill: colors.$neutral-900;
    }
  }
}

.vl-behavior-tooltip {
  .tooltiptext {
    left: -127px;

    &:after {
      left: 137px;
    }
  }
}

.vl-table-content {
  td {
    padding-top: theme.$spacing-base;
    font-size: 1.5rem !important;
    .table-versus-behavior-price-versus {
      svg {
        width: 20px;
        height: 20px;
      }
      .table-versus-behavior-percentage {
        &:after {
          font-size: theme.$fsize-base;
        }
      }
    }
  }

  th,
  td {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  th:last-child,
  td:last-child {
    border-right: 0 !important;
  }

  th:first-child,
  td:first-child {
    padding-left: 0 !important;
  }
}

.vl-table-data-row {
  display: flex;
}

.vl-table-detail-data:after {
  content: '%';
  font-size: theme.$fsize-base;
}

.vl-table-versus-row {
  display: flex;
}

.vl-table-behavior-icon {
  height: 20px;
  width: 20px;
}

.vl-table-detail-footer {
  padding-top: 20px;
  p {
    color: colors.$neutral-800;
    font-size: theme.$fsize-4;
    line-height: 1.45;
  }
  strong {
    font-weight: bold;
  }
}

@media screen and (max-width: 1024px) {
  .volume-table-container {
    padding: theme.$spacing-xl 0;
  }

  .vl-table-content {
    display: inline;
    td,
    th {
      padding: 8px 0 !important;
    }
  }

  .vl-table-content-container {
    .volume-behavior-table {
      &.table-responsive {
        tr {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        th {
          display: inherit;
          align-items: center;
          .tooltip {
            width: 20px;
          }
        }
        td,
        th {
          width: 100%;
        }

        tr {
          &:nth-last-of-type(-n + 1) {
            margin-bottom: theme.$spacing-l;
          }
        }
      }

      &.table-desktop {
        tr {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        th {
          display: inherit;
          align-items: center;
          .tooltip {
            width: 20px;
          }
        }
        td,
        th {
          border: none;
          width: 100%;
        }

        tr {
          &:nth-last-of-type(-n + 1) {
            margin-bottom: theme.$spacing-l;
          }
        }
      }
    }
  }

  .vl-table-inner-container {
    padding: theme.$spacing-l;
  }

  .table-responsive-volume-detail-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    tbody {
      width: 47%;
      th {
        padding-left: 5px !important;
      }
    }
  }

  .vl-table-detail-footer {
    padding-bottom: 20px;
  }

  .vl-tbl-date-container {
    padding: 10px 0;
  }
}
