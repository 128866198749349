@use 'src/styles/theme';

.grid {
  --grid-cols: 12;
  display: grid;
  grid-template-columns: repeat(var(--grid-cols), 1fr);
  grid-gap: theme.$spacing-l; // 24px

  & > * {
    --span: var(--grid-cols);
    grid-column: span var(--span) / span var(--span);
  }

  @media (min-width: 1024px) {
    grid-gap: theme.$spacing-xl; // 32px
  }
}

@for $i from 1 through 12 {
  .col-#{$i} {
    --span: #{$i};
  }
  .col-start-#{$i} {
    grid-column-start: #{$i};
  }
}
