@use 'src/styles/theme';

// general
.border { border: 1px solid }
.border-none { border: none }
.bsd { border-style: dashed }

// border radius
.br-inherit { border-radius: inherit }
.br50 { border-radius: 50% }
.br-base { border-radius: theme.$spacing-base }
.br-l { border-radius: theme.$spacing-l }
.br-m { border-radius: theme.$spacing-m }
