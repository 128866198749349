@use 'src/styles/theme';
@use 'src/styles/colors';

.daily-locations-graph-container {
  min-height: 425px;
}

.dly-lcs-graph-inner-container {
  padding: theme.$spacing-base 0;
  position: relative;

  .dly-lcs-graph-yaxis-label {
    color: colors.$neutral-700;
    font-size: theme.$fsize-6;
    font-weight: 500;
    height: 24px;
    line-height: 2.4;
    margin-left: 10px;
  }
}

.dly-lcs-graph-highcharts-ylabel {
  color: colors.$neutral-900;
  font-size: theme.$fsize-6;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 5px;
  margin-left: 10px;
}

.dly-lcs-graph-description {
  color: colors.$neutral-700;
  font-size: theme.$fsize-4;
  margin: theme.$spacing-l 0;
  width: 683px;
}

.dly-graph-custom-xaxis-month-label {
  bottom: 30px;
  color: colors.$neutral-700;
  font-size: theme.$fsize-5;
  font-weight: 500;
  line-height: 2.4;
  position: absolute;
}

.dly-xaxis-month-left {
  left: 54px;
}

.dly-xaxis-month-right {
  right: 10px;
}
.dly-lcs-filter-container {
  margin-bottom: theme.$spacing-m;
}

@media screen and (max-width: 1024px) {
  .dly-lcs-graph-description {
    width: 100%;
  }
}
