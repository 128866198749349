@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/mixins/mq';

.row-calendar-container {
  margin-top: 20px;
  > span {
    display: flex;
    justify-items: center;

    .market-place-tooltip {
      margin-left: 5px;

      .tooltiptext {
        left: -110px;

        &:after {
          left: 120px;
        }
      }
      @include mq.lt-sm {
        .tooltiptext {
          left: -170px;

          &:after {
            left: 178px;
          }
        }
      }
    }
  }

  .row-clndr-header {
    color: colors.$neutral-700;
    font-size: theme.$fsize-2;
    line-height: 1.71;
  }

  hr {
    margin-top: theme.$spacing-base;
  }

  .row-clndr-content {
    display: flex;
    flex-direction: column;
  }
}
