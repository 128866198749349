@use 'src/styles/theme';

.hide {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.dn { display: none !important }

@media screen and (max-width: #{theme.$screen-xs-max}) {
  .xs-hide { display: none !important }
}

@media screen and (min-width: #{theme.$screen-sm-min}) and (max-width: #{theme.$screen-sm-max}) {
  .sm-hide { display: none !important }
}

@media screen and (min-width: #{theme.$screen-md-min}) and (max-width: #{theme.$screen-md-max}) {
  .md-hide { display: none !important }
}

@media screen and (min-width: #{theme.$screen-lg-min}) and (max-width: #{theme.$screen-lg-max}) {
  .lg-hide { display: none !important }
}

@media screen and (min-width: #{theme.$screen-xl-min}) and (max-width: #{theme.$screen-xl-max}) {
  .xl-hide { display: none !important }
}

// lt
@media screen and (max-width: #{theme.$screen-xs-max}) {
  .lt-sm-hide { display: none !important }
}

@media screen and (max-width: #{theme.$screen-sm-max}) {
  .lt-md-hide { display: none !important }
}

@media screen and (max-width: #{theme.$screen-md-max}) {
  .lt-lg-hide { display: none !important }
}

@media screen and (max-width: #{theme.$screen-lg-max}) {
  .lt-xl-hide { display: none !important }
}

// gt
@media screen and (min-width: #{theme.$screen-sm-min}) {
  .gt-xs-hide { display: none !important }
}

@media screen and (min-width: #{theme.$screen-md-min}) {
  .gt-sm-hide { display: none !important }
}

@media screen and (min-width: #{theme.$screen-lg-min}) {
  .gt-md-hide { display: none !important }
}

@media screen and (min-width: #{theme.$screen-xl-min}) {
  .gt-lg-hide { display: none !important }
}
