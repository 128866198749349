@use 'src/styles/theme';
@use 'src/styles/colors';

.login-button-container {
  position: relative;
  width: 100%;
  margin-top: 2px;

  &.navbar-button > .navbar-button__content-wrapper {
    height: auto;

    &::before {
      border-radius: theme.$spacing-xl 0 0 theme.$spacing-xl;
    }
  }

  .login-button {
    background-color: colors.$primary-900;
    border-radius: theme.$spacing-xl;
    border: 2px solid colors.$white-100;
    cursor: pointer;
    height: theme.$spacing-xl;
    outline: none;
    width: theme.$spacing-xl;

    .login-button-user-photo {
      border-radius: theme.$spacing-xl;
      height: 100%;
      width: 100%;
    }

    .login-button-user-icon {
      height: 66%;
      width: 66%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .login-button-container {
    width: auto;
    margin: 0;

    &.navbar-button > .navbar-button__content-wrapper {
      height: theme.$sider-mobile-height-content;

      &::before {
        border-radius: 0 0 theme.$spacing-xl theme.$spacing-xl;
      }
    }
  }
}
