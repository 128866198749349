@use 'src/styles/theme';

.programs-tab-checkbox-wrapper {
  & .fk-checkbox-container {
    margin: 0;

    & > label {
      font-size: theme.$fsize-2;
      padding-left: theme.$spacing-base;

      &::after {
        content: initial;
      }
    }
  }
}

.programs-tab-title {
  margin-top: theme.$spacing-l;
  font-weight: 700;
}
