@use 'src/styles/theme';
@use 'src/styles/colors';

.row-calendar-title {
  color: colors.$neutral-700;
  font-size: theme.$fsize-4;
  font-weight: 500;
  line-height: 1.33;
  margin-bottom: theme.$spacing-base;
  margin-top: theme.$spacing-l;
}
