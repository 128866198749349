@use 'src/styles/theme';

.fs-6 { font-size: theme.$fsize-6 }
.fs-4 { font-size: theme.$fsize-4 }
.fs-3 { font-size: theme.$fsize-3 }
.fs-2 { font-size: theme.$fsize-2 }
.fs-1 { font-size: theme.$fsize-1 }
.fs-base { font-size: theme.$fsize-base }
.fs1 { font-size: theme.$fsize1 }
.fs3 { font-size: theme.$fsize3 }

.fw400 { font-weight: 400 }
.fw500 { font-weight: 500 }
.fw600 { font-weight: 600 }
.fw700 { font-weight: 700 }

.tdn { text-decoration: none }
