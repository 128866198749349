@use 'src/styles/theme';
@use 'src/styles/colors';

.document-container {
  .document-anchor {
    border-radius: 8px;
    border: 1px solid colors.$primary-900;
    display: flex;
    padding: theme.$spacing-base theme.$spacing-m;
    width: min-content;

    color: colors.$primary-900;
    font-size: theme.$fsize-4;
    font-weight: 600;
    line-height: 1.33;
    text-align: center;
    text-decoration: none;
  }
}
