@use 'src/styles/theme';
@use 'src/styles/colors';

.event-details-container {
  background-color: colors.$neutral-50;
  margin-top: -8px;
  border-radius: 8px;
  padding: theme.$spacing-base theme.$spacing-m;

  .evnt-dtls-dflt-txt {
    color: colors.$neutral-900;
    font-size: theme.$fsize-5;
    line-height: 1.45;
  }

  .event-details-default {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    .evnt-dtls-dflt-txt {
      &:nth-child(2) {
        margin: 0 theme.$spacing-m;
      }
    }
  }

  .evnt-dlts-details-button {
    background-color: transparent;
    border: 0;
    color: colors.$primary-900;
    cursor: pointer;
    font-size: theme.$fsize-5;
    font-weight: 700;
    line-height: 1.45;
    outline: none;
  }

  .evnt-dtls-toggle-menu {
    width: 100%;

    .evnt-dtls-tggle-header {
      width: 100%;
      display: flex;
      justify-content: space-between;

      h3 {
        color: colors.$neutral-900;
        font-size: theme.$fsize-base;
      }
    }

    .evnt-dtls-tggle-content {
      display: flex;
      flex-direction: column;
      margin: theme.$spacing-base 0;

      .evnt-dtls-tggle-cntnt-data-row {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .evnt-dtls-tggle-cntnt-dta-icon {
          height: 8px;
          margin-left: 4px;
          margin-right: 14px;
          margin-top: 4px;
          width: 8px;
          g {
            fill: colors.$neutral-500;
          }
        }

        .evnt-dtls-icon-cntnt {
          display: flex;
        }

        .evnt-dtls-tggle-cntnt-dta-warning {
          height: 16px;
          width: 16px;
          margin-right: 10px;
          path {
            fill: colors.$yellow-800;
          }
        }

        .evnt-dtls-tggle-cntnt-dta-error {
          height: 16px;
          width: 16px;
          margin-right: 10px;
          path {
            fill: colors.$red-900;
          }
        }

        .evnt-dtls-dflt-stppr {
          display: inline-block;
          width: 100%;
          height: 15px;
          border-left: 2px dotted colors.$neutral-400;
          margin: 5px 7px;
        }

        .evnt-dtls-download-row {
          display: flex;
          align-items: center;
          align-content: center;

          .evnt-dtls-dflt-txt {
            margin-top: 3px;
          }
        }

        .evnt-dtls-download-document {
          &.document-container {
            margin-top: 0;
            margin-right: 10px;
          }

          .document-component {
            padding-top: 0;
            padding-bottom: 0;
            border: none;
            background-color: transparent;
          }
        }
      }
    }
    .evnt-dtls-tggle-divisor {
      margin-top: theme.$spacing-m;
    }

    .status-message-divisor {
      margin-top: theme.$spacing-l;
    }
    .status-message-container {
      padding: theme.$spacing-m 0;

      .status-message-text {
        color: colors.$neutral-900;
        font-size: theme.$fsize-2;
        line-height: 1.71;
      }

      .status-message-contact {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: theme.$spacing-m;
        width: 55%;

        > div {
          display: inherit;
          flex-direction: column;
          height: 100%;

          h5 {
            font-weight: 500;
          }

          a {
            margin-top: theme.$spacing-base;
          }
        }

        .phone-tag {
          color: colors.$neutral-900;
          font-size: theme.$fsize-base;
          font-weight: 700;
          line-height: 1.5;
        }
      }
    }
    .error-message-title {
      margin-top: 40px;
      margin-bottom: -10px;
    }
  }
}

.event-status-warning {
  background-color: colors.$yellow-50;
}

.event-status-error {
  background-color: colors.$red-50;
}
