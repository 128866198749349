@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/mixins/mq';

.message-container {
  --duration: 270ms;

  @media (prefers-reduced-motion) {
    --duration: 0ms;
  }

  padding: 0 theme.$spacing-m;
  top: 0;
  left: 0;
  position: fixed;
  overflow: auto;
  z-index: theme.$confirm-dialog-index;
  opacity: 0;
  transition: opacity var(--duration) linear;

  .message-card {
    max-width: 512px;
    margin: 112px auto theme.$spacing-m;
    background-color: colors.$white-100;
    border-radius: theme.$spacing-l;
    border: solid 1px colors.$neutral-700;
    box-shadow: 0 16px 24px -24px rgba(20, 20, 20, 0.8);
    padding: theme.$spacing-m;
    transform: translate(0, theme.$spacing-xl);
    transition: transform var(--duration) ease;

    @include mq.lt-sm {
      padding: theme.$spacing-base;
    }

    .message-card-content {
      border-radius: inherit;
      border: solid 1px colors.$neutral-300;
      padding: 40px theme.$spacing-xl;

      @include mq.lt-sm {
        padding: 40px theme.$spacing-m;
      }

      .message-card-content-title {
        color: colors.$neutral-900;
        font-size: 26px;
        font-weight: 700;
        line-height: 1.23;
        text-align: center;
      }

      .message-card-content-description {
        color: colors.$neutral-700;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.6;
        margin-top: theme.$spacing-m;
        margin-bottom: theme.$spacing-l;
        text-align: center;
      }

      .message-card-content-footer {
        width: 100%;
        .message-card-content-button-container {
          display: flex;
          justify-content: center;
          > button {
            &:not(:first-child) { margin-left: theme.$spacing-m }

            @include mq.lt-sm {
              padding: 12px theme.$spacing-m;
            }
          }
        }
      }
    }
  }
}

.message-container--active {
  opacity: 1;

  & > .message-card {
    transform: translate(0, 0);
  }
}
