@use 'src/styles/theme';
@use 'src/styles/colors';

.change-language-container {
  margin-top: theme.$spacing-m;

  @media (max-width: 1024px) {
    margin-top: 0;
  }

  > .change-language-button {
    background-color: colors.$primary-900;
  }

  .change-language-button {
    position: relative;

    &.language-selected-true {
      border-color: colors.$primary-300;
    }

    > p {
      background-color: colors.$primary-800-80;
      color: colors.$white-100;
      font-size: theme.$fsize-6;
      font-weight: 700;
      line-height: 0.8;
      padding: theme.$spacing-s 0;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.change-language-icon {
  width: 100%;
  height: 100%;
}
