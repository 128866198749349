@use 'src/styles/theme';
@use 'src/styles/colors';

.volume-select-cascader-container {
  border-radius: 16px;
  border: solid 1px colors.$neutral-200;
  max-width: 480px;
  padding: theme.$spacing-m;
}

.volume-select-cascader-row {
  display: flex;
  align-items: center;
  padding: theme.$spacing-m;

  .volume-select-cascader {
    padding: 0;
  }

  .volume-select-cascader-button {
    height: 32px;
    width: 32px;
    padding: theme.$spacing-base;

    &.remove-volume-select-button {
      margin-right: theme.$spacing-m;
    }

    .volume-select-button-icon {
      width: 16px;
      height: 16px;

      &.add-volume-select-button-icon {
        path,
        g,
        svg {
          fill: colors.$primary-900;
        }
      }

      &.remove-volume-select-button-icon {
        path,
        g,
        svg {
          fill: colors.$red-900;
        }
      }
    }
  }

  &:first-of-type {
    .volume-select-cascader-button {
      margin-top: 24px;
    }
  }

  &:nth-last-of-type(n + 2) {
    .add-volume-select-button {
      display: none;
    }
  }

  &:nth-of-type(n + 2) {
    .select-cascader {
      .select-row {
        .select-label-title {
          display: none;
        }
      }
    }
  }
}
.select-cascader-button-responsive {
  display: none;
}

.volume-select-apply-filters-button {
  margin-right: theme.$spacing-base;
  margin-left: theme.$spacing-m;
}

.dashboard-divisor-responsive {
  display: none;
}

@media screen and (max-width: 1024px) {
  .volume-select-cascader-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .volume-select-cascader-row {
    justify-content: space-around;
    padding: 0;
    &:first-of-type {
      .volume-select-cascader-button {
        margin: 0;
      }
    }
  }

  .dashboard-divisor-responsive {
    margin-top: theme.$spacing-m;
    display: inherit;
  }

  .volume-select-cascader-row-container-true {
    padding-bottom: theme.$spacing-m;
  }

  .select-cascader-button-desktop {
    display: none;
  }

  .select-cascader-button-responsive {
    display: block;
    margin-top: theme.$spacing-m;
  }

  .volume-select-button-icon {
    width: 16px;
    height: 16px;

    &.add-volume-select-button-icon {
      path,
      g,
      svg {
        fill: colors.$primary-900;
      }
    }
  }

  .volume-select-apply-filters-button {
    margin: theme.$spacing-m 0;
  }
}
