@use 'src/styles/theme';
@use 'src/styles/colors';

.select-component-container {
  position: relative;
  margin-bottom: 20px;

  [class^='select-component-button-text-'] {
    line-height: 1.33;
    margin-left: 5px;
    margin-right: 10px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
  }

  .select-selected-after-text-false {
    display: none;
  }

  .select-selected-after-text-true,
  .select-component-button-after-text-select-custom {
    color: colors.$neutral-800;
    font-size: theme.$fsize-5;
    font-weight: 400;
    line-height: 1.6;
    margin-right: 10px;
    margin-top: 2px;
    width: max-content;
  }

  button {
    align-items: center;
    color: colors.$neutral-900;
    background-color: colors.$white-100;
    border-radius: theme.$spacing-base;
    border: solid 1px colors.$neutral-300;
    display: flex;
    font-size: theme.$fsize-2;
    font-weight: 400;
    line-height: 1.14;
    outline: none;
    padding: theme.$spacing-base theme.$spacing-m;
    width: 100%;
    height: theme.$select-height;

    &:hover {
      cursor: pointer;
      border-color: colors.$primary-500;
    }

    &:active {
      border-color: colors.$primary-700;
    }

    &:focus {
      border-color: colors.$primary-500;
      box-shadow: 0 0 0 3px colors.$green-800-60;
    }

    &.disabled-true {
      background-color: colors.$neutral-100;

      &:hover {
        cursor: not-allowed;
        border-color: colors.$neutral-500;
      }
    }
  }

  .select-component-button-active-true {
    border-color: colors.$primary-700;
  }

  .select-button-arrow-icon {
    margin-left: auto;
    width: 11px;
    height: 11px;

    path {
      fill: colors.$neutral-900;
    }
  }
}

[class^='sl-floating-modal-container-'] {
  background-color: colors.$white-100;
  border-radius: theme.$spacing-base;
  border: solid 1px colors.$neutral-300;
  box-shadow: 0 8px 8px -4px colors.$primary-800-10;
  left: 0;
  position: absolute;
  top: 48px;
  min-width: 155px;
  width: 100%;
  z-index: 100;

  [class^='sl-floating-options-'] {
    border-radius: inherit;
    list-style-type: none;

    li {
      border-top-color: colors.$neutral-200;
      border-top-style: solid;
      border-top-width: 1px;
      color: colors.$neutral-900;
      font-size: theme.$fsize-2;
      font-weight: 500;
      line-height: 1.33;
      padding: theme.$spacing-m;

      &:hover {
        background-color: colors.$primary-50;
        color: colors.$primary-800;
        cursor: pointer;
      }
    }

    .sl-floating-option-selected-true {
      color: colors.$primary-800;
    }

    li:first-child {
      border-top: 0 !important;

      &:hover {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
      }
    }

    li:last-child {
      &:hover {
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .select-component-container {
    [class^='select-component-button-text-'] {
      font-size: theme.$fsize-5;
      line-height: 1.71;
    }
  }
}

@media screen and (max-width: 424px) {
  .select-component-container {
    width: 100%;

    [class^='select-component-button-text-'] {
      font-size: theme.$fsize-4;
    }
  }
}
