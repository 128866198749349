@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/layout/content/fixGrid.scss';

.content-shell {
  padding: theme.$spacing-l 10px 0;
}

.grid-layout {
  display: grid;
  grid-gap: theme.$spacing-m;
  grid-template-columns: theme.$sider-desktop-width 1fr;
  margin: 0 auto;
  max-width: 1680px;

  @media (max-width: 1024px) {
    display: block;
    margin: 0;
  }
}

.sider-wrapper {
  z-index: theme.$sider-index;
  background-color: colors.$white-100;

  @media (max-width: 1024px) {
    position: fixed;
    left: 10px;
    right: 10px;
    bottom: 0;
    padding-bottom: theme.$spacing-m;
    border-radius: theme.$spacing-m theme.$spacing-m 0 0;
  }
}

.content-wrapper {
  min-width: 0;

  @media (max-width: 1024px) {
    padding-bottom: theme.$sider-mobile-height + theme.$spacing-m;
  }
}
