@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/components/Button/style.scss';

.login-form-container {
  background-color: colors.$white-90;
  border-radius: 40px;
  box-shadow: 0 6px 16px 0 colors.$neutral-800-40;
  padding: theme.$spacing-3xl theme.$spacing-l;
  width: 100%;
}

.lf-title,
.lf-header-logo {
  align-items: center;
  display: flex;
}

.lf-header-inner {
  display: flex;
  align-items: center;

  .lf-header-logo {
    color: colors.$primary-900;
    font-size: theme.$fsize2;
    font-weight: bold;
    margin-top: 10px;

    .lf-logo {
      height: 40px;
    }
  }
}

.lf-title {
  color: colors.$neutral-900;
  font-size: 1.4em;
  font-weight: bold;
  line-height: 1.14;
  margin-top: 24px;
}

.lf-content-form {
  display: inline;

  div {
    &:nth-of-type(n + 2) {
      margin-top: theme.$spacing-l;
    }
  }

  .lf-c-form-submit-button {
    @extend .fk-button, .button-secondary-main, .button-small;
    margin-top: 20px;
  }

  .lf-content-form-error {
    color: colors.$red-900;
    display: none;
    font-size: theme.$fsize-5;
    line-height: 1.6;
    margin-top: theme.$spacing-base;
    width: 100%;

    &.is-error-true {
      display: inline;
    }
  }
}

@media screen and (max-height: 700px) {
  .lf-title {
    font-size: 1.3em;
    margin-top: theme.$spacing-base;
  }
}

@media screen and (max-width: 1024px) {
  .login-form-container {
    padding: theme.$spacing-l theme.$spacing-l;
  }

  .lf-content {
    margin-top: theme.$spacing-l;
  }

  .lf-title {
    margin-top: theme.$spacing-base;
  }

  .lf-header {
    margin-top: theme.$spacing-l;
  }

  .lf-content-form .lf-c-form-submit-button {
    margin-top: theme.$spacing-xl;
    margin-bottom: theme.$spacing-l;
  }
  .login-container .login-form-card-container {
    padding-top: theme.$spacing-xl;
  }
}
