.forecast-container {
  padding: 0 3px;
  height: 100%;
}

.forecast-content {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
}

@media screen and (max-width: 1024px) {
  .forecast-content {
    display: grid;
    grid-template-columns: repeat(7, calc(100%/7));
  }
}

@media screen and (max-width: 700px) {
  .forecast-content {
    display: flex;
    flex-direction: column;
  }
}
