@use 'src/styles/theme';

.projection-graph-container {
  width: 100%;
  height: 100%;

  .proj-graph-content {
    height: 100%;
    margin-top: theme.$spacing-l;
    width: 100%;
  }
}
