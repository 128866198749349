@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/mixins/mq';

.header-card__container {
  position: sticky;
  top: 0;
  z-index: 1;
  border-radius: 16px;
  background-color: colors.$primary-900;
  color: colors.$white-100;
  box-shadow: 0 32px 16px -24px rgba(49, 50, 51, 0.1), 0 16px 16px -24px rgba(49, 50, 51, 0.3);

  @include mq.lt-sm {
    top: theme.$spacing-l;
  }
}

.header-card__content,
.header-card__footer {
  border-radius: inherit;
}

.header-card__content {
  display: flex;
  padding: theme.$spacing-m 0 theme.$spacing-base theme.$spacing-m;
  background-color: colors.$primary-800;
}

.header-card__icon__container {
  margin-top: theme.$spacing-s;
  margin-right: theme.$spacing-base;

  .header-card__icon__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: theme.$spacing-base;
    border-radius: 50%;
    border: 2px solid colors.$white-100;
    background-color: colors.$primary-900;
  }
}

.header-card__content__information {
  .header-card__content__information__title {
    font-size: theme.$fsize3;
    line-height: 1.5;
  }
  .header-card__content__information__version {
    font-size: theme.$fsize-6;
    line-height: 1.6;
    color: colors.$primary-100;

    &:before {
      content: 'Frida V ';
    }
  }
}

.header-card__footer {
  padding: theme.$spacing-base theme.$spacing-l;
  min-height: 51px;
}
