@use 'src/styles/theme';
@use 'src/styles/colors';

// HEADER
.weather-container {
  padding: theme.$spacing-xl 0;
}

.weather-header-title > h3 {
  color: colors.$neutral-800;
  font-size: theme.$fsize1;
  font-weight: 600;
  padding: theme.$spacing-base 0;
}

.weather-header-description {
  display: flex;
  position: relative;
}

.weather-content {
  min-height: 164px;
}

.weather-header-description > p {
  color: colors.$neutral-700;
  font-size: theme.$fsize-2;
  height: 48px;
  line-height: 1.71;
  width: 683px;
}

.weather-header-description > p > b {
  font-weight: 600;
}

.warning-red-color {
  color: colors.$red-900 !important;
}

//TABLE STYLES
.weather-table {
  border-collapse: collapse;
  margin-top: 25px;
  width: 100%;
}

.weather-table-head-row > th {
  color: colors.$neutral-700;
  font-size: theme.$fsize-4;
  font-weight: 500;
  height: 18px;
  padding-bottom: theme.$spacing-base;
}

.weather-table-body {
  position: relative;
}

.weather-table-body-row {
  border-top-color: colors.$neutral-200;
  border-top-style: solid;
  border-top-width: 1px;
  cursor: pointer;
  outline: none;
}

.weather-table-body-row:hover {
  background-color: colors.$neutral-50;
}

.weather-table-status-icon {
  height: 23px;
  width: 23px;
}

.weather-table-alert-type-icon {
  width: 23px;
  height: 23px;
}

.weather-table-alert {
  width: 100px;
}

.weather-table-growing-region {
  color: colors.$neutral-900;
  font-weight: 500;
  font-size: theme.$fsize-2;
}

.weather-table-description-text {
  color: colors.$neutral-800;
  font-size: theme.$fsize-1;
  font-weight: 400;
}

.description-text {
  color: colors.$neutral-800;
  width: 210px;
}

#weather-table-alert-text {
  color: #5727eb;
  font-size: theme.$fsize-base;
  line-height: 1.33;
  text-align: left;
  text-decoration: underline;
}

.weather-table-degrees {
  color: colors.$neutral-800;
  font-size: theme.$fsize-2;
  font-weight: 400;
  text-transform: capitalize;
  width: 110px;
}

// Forecast

.weather-table-active-row {
  border-bottom-color: rgba(205, 190, 249, 1);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-top-color: rgba(205, 190, 249, 1);
  border-top-style: solid;
  border-top-width: 1px;
  background-color: #eee9fd;
}

.weather-table-active-row:hover {
  border-bottom-color: colors.$neutral-200;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-top-color: colors.$neutral-200;
  border-top-style: solid;
  border-top-width: 1px;
}

.weather-forecast-daily-container {
  background-color: colors.$white-100;
  height: 120px;
}

// overwrites
// Align text to center to th and td
.weather-table-head-row > th,
.weather-table-body-row > td {
  text-align: center;
}

// Spacing for top and bottom for td
.weather-table-body-row > td {
  padding: theme.$spacing-m 0;
}

// Custom size for status row
.weather-table-status-row {
  width: 50px;
}

// Custom size for growing area
.growing-text {
  width: 200px;
}

// Custom size for alert type
.alert-type {
  width: 130px;
}

.weather-description-row {
  display: flex;
  align-items: center;
}

.weather-description-icon {
  margin-right: 30px;
  margin-left: 10px;
}

.weather-description-text {
  font-size: theme.$fsize-2;
}

// Overwrite the text align for custom styles
.row-left {
  text-align: left !important;
}

// text overflow for descriptions and cities
.table-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// toggle
.forecast-toggle-labels:before {
  content: '°';
}

.weather-header-description > .toggle-switch-container {
  align-self: flex-end;
  position: absolute;
  right: 0;
}

@media screen and (max-width: 1024px) {
  .weather-container {
    overflow: hidden;
    padding: theme.$spacing-xl 0;
  }
}

@media screen and (max-width: 700px) {
  .desktop-layout {
    display: none;
  }
  .weather-header-description {
    flex-direction: column;

    p {
      width: fit-content;
      height: fit-content;
    }

    .toggle-switch-container {
      position: relative;
    }
  }
}
