@use 'src/styles/theme';
@use 'src/styles/colors';

.toggle-switch-container {
  display: flex;
  align-items: center;
}

.toggle-switch-title {
  color: colors.$neutral-700;
  font-size: theme.$fsize-3;
  font-weight: 400;
  line-height: 1.23;
}

.toggle-switch-true-value,
.toggle-switch-false-value {
  text-transform: uppercase;
  color: colors.$neutral-900;
  font-size: theme.$fsize-2;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.23;
  text-align: right;
  margin-right: 16px;
  margin-left: 16px;
}

.toggle-switch-active-label {
  font-weight: 600;
}

.toggle-switch {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 32px;
}

.toggle-switch #toggle-switch-input {
  height: 0;
  opacity: 0;
  width: 0;
}

.toggle-switch-slider {
  -webkit-transition: 0.4s;
  background-color: colors.$neutral-200;
  box-shadow: inset 0 1px 2px 0 colors.$neutral-800-10;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;

  &.disabled-true {
    cursor: not-allowed;
  }
}

.toggle-switch-slider:before {
  background-color: colors.$primary-900;
  bottom: 4px;
  box-shadow: inset 0 0 0 4px colors.$primary-700;
  content: '';
  height: 24px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 24px;
}

// TODO: check the state for the toggle
// #toggle-switch-input:checked + .toggle-switch-slider {
//   background-color: #2196f3;
// }

// #toggle-switch-input:focus + .toggle-switch-slider {
//   box-shadow: 0 0 1px #2196f3;
// }

#toggle-switch-input:checked + .toggle-switch-slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

// If needed a square toggle just manage this.
.slider-round,
.slider-round:before {
  border-radius: 16px;
}
