@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/mixins/mq';

.detail-opportunity-wrapper {
  height: 100%;

  @include mq.lt-sm {
    display: none;
    overflow-x: hidden;
    padding: theme.$spacing-m 10px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    background-color: colors.$white-100;

    &.selected-program-true {
      display: inherit;
      z-index: 30;
    }
  }
}

.detail-opportunity-card {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  background-color: colors.$primary-50;
  position: relative;

  .detail-prog-divisor {
    margin: 12px 0;
  }

  &.selected-program-true {
    @include mq.lt-sm {
      height: unset;
      max-height: unset;

      .detail-program-container {
        overflow: unset;
        height: unset;
      }

      .detail-program-scroll-wrapper {
        height: fit-content;
        max-height: unset;
        overflow-y: unset;
      }

      .render-resume-true {
        .detail-program-scroll-wrapper {
          max-height: unset;
        }
      }
    }
  }
}

.detail-program-container {
  background-color: colors.$white-100;
  border-radius: 16px;
  box-shadow: 0 16px 24px -24px rgba(18, 13, 209, 0.8);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .detail-program-scroll-wrapper {
    left: 0;
    overflow-y: auto;
    padding: theme.$spacing-xl;
    top: 0;

    @include mq.lt-sm {
      padding: theme.$spacing-xl theme.$spacing-l;
    }
  }

  .dtail-prog-header {
    height: 280px;
    padding: theme.$spacing-m 0;
    width: 100%;

    h3 {
      font-size: 1.8rem;
    }

    @include mq.lt-sm {
      display: none;
    }
  }

  .dtail-prog-content {
    margin-top: theme.$spacing-xl;
  }

  .back-dummy-button {
    margin-top: 10px;
    max-width: 150px;
  }
}
