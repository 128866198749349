@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/mixins/mq';

.week-row-container {

  .week-rw-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .week-rw-hdr-title {
      color: colors.$neutral-700;
      font-size: theme.$fsize-4;
      line-height: 1.33;
      margin-top: 20px;
    }

    .week-rw-hdr-package-container {
      align-items: center;
      display: flex;

      .week-rw-hdr-package-icon {
        height: 16px;
        margin-top: 3px;
        width: 16px;

        &.unit-truck-icon {
          margin-top: 4px;
        }

        &.unit-package-icon {
          margin-top: 6px;
        }
      }

      .week-rw-hdr-package-value {
        color: colors.$neutral-900;
        font-size: theme.$fsize1;
        font-weight: 600;
        line-height: 1.33;
        margin-left: theme.$spacing-base;
        text-align: right;
      }
      .week-row-status {
        border-radius: 12px;
        color: colors.$neutral-900;
        font-size: theme.$fsize-6;
        font-weight: 700;
        line-height: 1.6;
        margin-left: 10px;
        padding: 4px 9.5px;
        text-align: center;
        text-transform: capitalize;

        &.status-pending {
          color: colors.$white-100;
          background-color: colors.$neutral-700;
        }

        &.status-success {
          background-color: colors.$green-200;
        }

        &.status-error {
          background-color: colors.$red-200;
        }

        &.status-warning {
          background-color: colors.$yellow-300;
        }
      }
    }
  }

  .week-rw-divisor {
    margin: theme.$spacing-base 0;
  }

  .week-rw-content {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;

    @include mq.lt-sm {
      grid-gap: 7px;
    }
  }

  .week-rw-footer {
    margin-top: theme.$spacing-base;
    text-align: right;
    p {
      color: colors.$neutral-900;
      font-size: theme.$fsize-6;
      letter-spacing: normal;
      line-height: 1.6;

      b {
        font-weight: 700;
      }
    }
  }
}
