@use 'src/styles/theme';
@use 'src/styles/colors';

.navbar-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: theme.$spacing-base 0 theme.$spacing-xl 0;
  position: sticky;
  top: 84px;
  width: 100%;
}

.navbar__buttons {
  width: 100%;
}

.navbar-button-wrapper {
  flex-shrink: 0;
}

.navbar-button {
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 14px;
    background-color: colors.$primary-900;
  }

  &:before { border-radius: 0 0 theme.$spacing-m 0; }
  &:after { border-radius: 0 theme.$spacing-m 0 0; }
}

.navbar-button__content-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 68px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 2px;
    right: 0;
    bottom: 0;
    border-radius: 50% 0 0 50%;
  }
}

.navbar-button__content {
  position: relative;

  &-icon { display: block }
}

.navbar-active {
  background-color: colors.$neutral-50;

  & > .navbar-button__content-wrapper {
    background-color: colors.$primary-900;

    &::before {
      background-color: colors.$neutral-50;
    }
  }
}

.navbar-actions-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
}

@media (max-width: 1024px) {
  .navbar-container {
    align-items: center;
    flex-direction: row;
    height: theme.$sider-mobile-height-content;
    justify-content: space-around;
    margin: 0;
    padding: 0 theme.$spacing-m;
    width: 100%;
  }

  .navbar__buttons {
    display: flex;
    justify-content: space-around;
  }

  .navbar-button {
    flex-direction: row;
    height: 100%;

    &:before,
    &:after {
      width: 10px;
      height: theme.$sider-mobile-height-content;
    }

    &:before { border-radius: 0 theme.$spacing-m 0 0 }
    &:after { border-radius: theme.$spacing-m 0 0 0 }

    & > .navbar-button__content-wrapper {
      width: 44px;
      height: 100%;

      &::before {
        left: 0;
        bottom: 12px;
        border-radius: 0 0 44px 44px;
      }
    }
  }

  .navbar-actions-wrapper {
    justify-content: space-around;
    flex-direction: row;
    margin-top: unset;

    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
      width: theme.$spacing-4xl;
    }
  }
}

/* =======================================================================
Shared styles for animated navbar menus:
  - CommodityToggler: fruit menu
  - ChangeLanguage: lang menu
Usage example:
  .navbar-anim([.active])
    .navbar-anim__control
    .navbar-anim__popover
      ul.navbar-anim__list
        li.navbar-anim__list-item
          button.navbar-anim__list-item-btn
        // As many items as needed.
        // Right now it supports up to 10 animated items
        // if more are needed just update the $total-items variable below to whatever you need
======================================================================= */
$menu-offset: 40px;
$transform-duration: 340ms;
$opacity-duration: 200ms;
$item-size-box: theme.$spacing-2xl + theme.$spacing-base;
$total-items: 10;

.navbar-anim {
  position: relative;

  &.active {
    & > .navbar-anim__popover {
      visibility: visible;
      opacity: 1;
      transform: translateX(theme.$spacing-m);
      transition: transform $transform-duration ease, opacity $opacity-duration, visibility 0s linear 0s;
      pointer-events: auto;
    }

    .navbar-anim__list-item { transform: translate(0, 0) }
  }
}

.navbar-anim__control {
  display: flex;
  align-items: center;
  justify-content: center;
  width: theme.$spacing-xl;
  height: theme.$spacing-xl;
  overflow: hidden;
  border: 2px solid colors.$white-100;
  cursor: pointer;
  border-radius: 50%;

  #icn-system-cancel-status-icon { fill: colors.$white-100 }
}

.navbar-anim__popover {
  position: absolute;
  top: -#{theme.$spacing-base};
  left: 100%;
  visibility: hidden;
  opacity: 0;
  transform: translateX($menu-offset * -1);
  transition: transform $transform-duration ease-out, opacity $opacity-duration, visibility 0s linear 1s;
  pointer-events: none;
}

.navbar-anim__list {
  display: flex;
  list-style: none;
}

.navbar-anim__list-item {
  width: theme.$spacing-2xl;
  height: theme.$spacing-2xl;
  transition: transform $transform-duration ease;

  &:not(:last-child) { margin: 0 theme.$spacing-base 0 0 }

  @for $i from 2 through $total-items {
    &:nth-child(#{$i}) { transform: translateX($item-size-box * ($i - 1) * -1) }
  }
}

.navbar-anim__list-item-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 2px solid colors.$white-100;
  cursor: pointer;
  border-radius: 50%;
  box-shadow:  0 4px 8px 0 rgba(20, 20, 20, 0.24);
}

@media (max-width: 1024px) {
  .navbar-anim.active > .navbar-anim__popover { transform: translate(-50%, -#{theme.$spacing-m}) }

  .navbar-anim__popover {
    top: auto;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, $menu-offset);
  }

  .navbar-anim__list { flex-direction: column-reverse }

  .navbar-anim__list-item {
    &:not(:last-child) { margin: theme.$spacing-base 0 0 0 }

    @for $i from 2 through $total-items {
      &:nth-child(#{$i}) { transform: translateY($item-size-box * ($i - 1)) }
    }
  }
}
