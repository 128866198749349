@use 'src/styles/theme';
@use 'src/styles/colors';

.timer-gif-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 60px;
}

.timer-label {
  color: colors.$primary-600;
  font-size: theme.$fsize2;
  font-weight: 600;
  line-height: 1.5;
  margin-top: 40px;
}
