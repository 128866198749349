@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/labels';

// HEADER
.shipping-point-behavior-container {
  margin-bottom: 10px;
  padding: theme.$spacing-m 0;
}

.shipping-point-behavior-header {
  display: flex;
  justify-content: space-between;
}

.shipping-point-behavior-header-title > h3 {
  color: colors.$neutral-800;
  font-size: theme.$fsize1;
  font-weight: 600;
}

.market-header-calendar-icon {
  height: 18px;
  margin-right: 5px;
  width: 18px;
}

.market-header-middle-icon {
  height: 18px;
  margin-left: 20px;
  margin-right: 5px;
  width: 18px;
}

.market-bh-header-date {
  align-items: center;
  color: colors.$neutral-800;
  display: flex;
  font-size: theme.$fsize-3;
  justify-content: flex-start;
  line-height: 1.33;
  margin-top: 10px;
  margin-bottom: 15px;
}

.market-header-date > p > strong,
.market-header-date > strong {
  font-weight: 600;
  margin-left: 3px;
  margin-right: 3px;
}

.shipping-point-behavior-header-description > p {
  color: colors.$neutral-700;
  font-size: theme.$fsize-2;
  height: 38px;
  line-height: 1.71;
  width: 683px;
  margin-bottom: 12px;
}

//TABLE
.shipping-point-behavior-internal-container {
  background-color: colors.$primary-800-10;
  padding: theme.$spacing-l theme.$spacing-xl;

  table {
    margin-bottom: theme.$spacing-l;
  }

  th > strong {
    font-weight: 600;
  }
}

.shipping-point-behavior-footer {
  color: colors.$neutral-800;
  font-size: theme.$fsize-5;
  font-weight: 400;
  line-height: 1.45;
  margin-top: 10px;
}

.shipping-point-behavior-divisor {
  margin: theme.$spacing-m 0;
}

.shipping-point-behavior-table-price {
  @extend .price-label;
}

.shipping-point-behavior-table-price:before,
.shipping-point-behavior-table-percentage:after {
  font-size: 1.5rem;
}

.help-icon {
  width: 15px;
  height: 15px;
  margin-left: theme.$spacing-base;
  vertical-align: text-top;
}

.help-icon g {
  fill: colors.$primary-800;
}

.shipping-point-dual-table table {
  display: inline-table;
}

@media screen and (max-width: 1024px) {
  .shipping-point-behavior-container {
    overflow: hidden;
    padding: theme.$spacing-m 0;
  }

  .shipping-point-behavior-internal-container {
    th > strong {
      font-weight: 600;
    }
  }
  .shipping-point-behavior-table-price:before,
  .shipping-point-behavior-table-percentage:after {
    font-size: theme.$fsize3;
  }
}
