// RESPONSIVENESS
$screen-sm-min: 600px;
$screen-md-min: 960px;
$screen-lg-min: 1280px;
$screen-xl-min: 1920px;

$screen-xs-max: 599px;
$screen-sm-max: 959px;
$screen-md-max: 1279px;
$screen-lg-max: 1919px;
$screen-xl-max: 5000px;

// SPACING
$spacing-s: 4px;
$spacing-base: 8px;
$spacing-m: 16px;
$spacing-l: 24px;
$spacing-xl: 32px;
$spacing-2xl: 48px;
$spacing-3xl: 56px;
$spacing-4xl: 64px;
$spacing-5xl: 72px;
$spacing-6xl: 80px;

// FONTS SIZES
$fsize-6: 0.625rem; //10px
$fsize-5: 0.6875rem; //11px
$fsize-4: 0.75rem; //12px
$fsize-3: 0.8125rem; //13px
$fsize-2: 0.875rem; //14px
$fsize-1: 0.9365rem; //15px
$fsize-base: 1rem; //16px
$fsize1: 1.125rem; //18px
$fsize2: 1.25rem; //20px
$fsize3: 2rem; //32px

// ZINDEX
$sider-index: 11;
$tooltip-index: 11;
$details-panel-index: 12;
$modal-index: 100;
$toast-message-index: 9999;
$confirm-dialog-index: 10000;

// Dimensions
$sider-desktop-width: 92px;
$sider-mobile-height: 80px;
$sider-mobile-height-content: 69px;
$select-height: 40px;

// Backgrounds
$login-background-1: '../../assets/backgrounds/login-background-1.svg';
$login-background-2: '../../assets/backgrounds/login-background-2.svg';
$detail-program-background: '../../../../../assets/backgrounds/ProgramDetailBgd.svg';
