@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/mixins/mq';

.day-card-container {
  background-color: colors.$white-100;
  border-radius: 4px;
  border: solid 1px colors.$primary-300;
  display: flex;
  flex-direction: column;
  margin-bottom: theme.$spacing-base;
  padding: theme.$spacing-base 0;

  .day-crd-day-name {
    color: colors.$neutral-800;
    font-size: theme.$fsize-6;
    line-height: 1.6;
    text-align: center;
    font-weight: 500;
    text-transform: capitalize;
  }

  .day-crd-day-number {
    color: colors.$neutral-900;
    margin: theme.$spacing-s 0;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;

    &.day-crd-current-date-true {
      background-color: colors.$red-900;
      border-radius: 37px;
      color: colors.$white-100;
      height: 24px;
      margin: theme.$spacing-s auto;
      padding: 3px;
      width: 24px;
    }
  }

  .day-crd-value {
    color: colors.$neutral-800;
    font-size: theme.$fsize-5;
    font-weight: 500;
    line-height: 1.45;
    text-align: center;
  }

  &.day-crd-container-value-true {
    background-color: colors.$primary-900;
    .day-crd-day-name,
    .day-crd-day-number,
    .day-crd-value {
      color: colors.$white-100 !important;
    }

    // EVENT STATUS ON VALUE CARD
    &.day-crd-status-success {
      background-color: colors.$green-900;

      .event-status-icon {
        width: 18px;
        height: 18px;
      }
    }

    &.day-crd-status-warning {
      border-color: colors.$yellow-800;
      background-color: colors.$yellow-800;

      .event-status-icon {
        width: 18px;
        height: 18px;
      }
    }

    &.day-crd-status-error {
      border-color: colors.$red-900;
      background-color: colors.$red-900;

      .event-status-icon {
        width: 18px;
        height: 18px;
      }
    }
  }

  // EVENT STATUS
  &.day-crd-status-warning {
    background-color: colors.$yellow-200;
    border-color: colors.$yellow-200;
  }

  @include mq.lt-sm {
    padding: theme.$spacing-base 0;
  }
}

.day-crd-label-container {
  display: flex;
  justify-content: space-around;
}

.day-crd-icon {
  width: 16px;
  height: 16px;
  margin-right: 3px;
  use,
  path {
    fill: colors.$white-100;
  }

  &.unit-package-icon {
    margin-top: 2px;
  }

  @include mq.lt-sm {
    display: none;
  }
}

.cancel-status-icon g {
  fill: colors.$white-100;
}

.check-status-icon path {
  fill: colors.$white-100;
}
