@use 'src/styles/theme';
@use 'src/styles/colors';

.tab-container {
  .tab-buttons-container {
    background-color: colors.$neutral-100;
    border-radius: 28px;
    box-shadow: inset 0 1px 2px 0 colors.$neutral-800-10;
    margin: theme.$spacing-l 0;
    width: fit-content;

    .tab-button {
      min-height: 40px;
      padding: theme.$spacing-base 12px;
      line-height: 1em;
      border: theme.$spacing-s solid transparent;
      background-color: transparent;
      border-radius: 20px;
      color: colors.$primary-800;
      font-weight: 700;

      &:nth-of-type(n + 2) {
        margin-left: theme.$spacing-s;
      }
    }
    .tab-button-active-true {
      background-color: colors.$primary-900;
      border-color: colors.$primary-700;
      box-shadow: 0 1px 4px 0 colors.$neutral-800-30;
      color: colors.$primary-50;
    }
  }
}
