@use 'src/styles/theme';
@use 'src/styles/colors';

// Selects labels
.select-label-title,
.volume-select-label-title {
  color: colors.$neutral-900;
  font-size: theme.$fsize-4;
  font-weight: 400;
  line-height: 1.33;
  margin-right: 10px;
}

.label-underlined {
  text-decoration: underline;
}

.price-label::before {
  content: '$';
}

.percent-label::after {
  content: '%';
}

.capitalize-label {
  text-transform: capitalize;
}

.uppercase-label {
  text-transform: uppercase;
}
