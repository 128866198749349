@use 'src/styles/theme';
@use 'src/styles/colors';

.volume-graph-container {
  padding: theme.$spacing-xl 0;
}

.volume-graph-header-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.volume-graph-select {
  display: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: colors.$white-100;
  // custom icon
  background-image: url('../../../../assets/icons/svg/icn---chevron---down.svg');
  background-position: right 0.9em top 55%, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: 15px, 100%;
  border-color: colors.$neutral-300;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  color: colors.$neutral-800;
  font-size: theme.$fsize-2;
  height: 40px;
  padding: 0 theme.$spacing-m;
  width: 144px;
}

// ISSUE IN SOME BROWSERS TO HIGLIGHT THE PADDING BORDER;
.volume-graph-select:focus {
  color: transparent;
  outline: none;
  font-size: theme.$fsize-2;
  text-shadow: 0 0 0 #000;
}

.volume-graph-select::-ms-expand {
  display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
}

.volume-graph-header-title > h3 {
  color: colors.$neutral-800;
  font-size: theme.$fsize1;
  font-weight: 600;
  padding: theme.$spacing-base 0;
}

.volume-graph-header-description > p {
  color: colors.$neutral-700;
  font-size: theme.$fsize-2;
  line-height: 1.71;
  width: 683px;
}

.volume-graph-description-text {
  color: colors.$neutral-700;
  margin: theme.$spacing-xl 0 theme.$spacing-l;
  font-size: theme.$fsize-4;
}

.volume-graph-content {
  height: 100%;
  padding: theme.$spacing-m 0;
}

.volume-report-buttons {
  display: flex;
  margin-top: theme.$spacing-l;
}

button.volume-category-button {
  background-color: colors.$white-100;
  border-color: colors.$primary-900;
  border-radius: 0px;
  color: colors.$primary-800;
  font-size: theme.$fsize-3;
  padding: theme.$spacing-base theme.$spacing-l;
}

button.volume-first-button {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

button.volume-last-button {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

button.volume-category-button-selected {
  background-color: colors.$primary-800;
  color: colors.$white-100;
}

.hc-volume-custom-tooltip,
.hc-volume-range-custom-tooltip {
  display: flex;
  width: 250px;

  div {
    display: flex;
    flex-direction: column;
    margin: 4px 3px;

    p {
      padding: 0;
      font-size: 11px;
      white-space: normal;
      + b {
        margin-top: 3px;
        margin-left: 5px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .volume-graph-container {
    padding: 0;
  }
  .volume-graph-header-description > p {
    width: 100%;
  }
  .volume-report-buttons button {
    padding: theme.$spacing-base theme.$spacing-m;

    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}

@media screen and (max-width: 700px) {
  .hc-volume-custom-tooltip,
  .hc-volume-range-custom-tooltip {
    display: flex;
    width: 250px;

    div {
      display: flex;
      flex-direction: column;
      margin: 4px 3px;

      p {
        padding: 0;
        font-size: 11px;
        white-space: normal;
        + b {
          margin-top: 3px;
          margin-left: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .volume-report-buttons button {
    padding: theme.$spacing-s theme.$spacing-s;
    font-size: theme.$fsize-4;
  }
}
