@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/programCard';
@use 'src/styles/mixins/mq';

.market-header-card-container {
  @extend.opportunity-card-container;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: colors.$primary-900;
  border-radius: theme.$spacing-l;
  box-shadow: 0 32px 16px -24px colors.$neutral-800-10, 0 16px 16px -24px colors.$neutral-800-30;

  @include mq.lt-sm {
    top: theme.$spacing-l;
  }

  &::before {
    content: '';
    position: absolute;
    top: -#{theme.$spacing-l};
    z-index: -1;
    width: 100%;
    height: theme.$spacing-2xl;
    background-color: colors.$white-100;
  }

  .mrkt-hdr-card-header {
    background-color: colors.$primary-800;
    padding: theme.$spacing-m;

    .mrkt-hdr-card-header-inner-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .mrkt-hdr-card-header-inner-wrapper {
        display: flex;
      }

      h3 {
        font-size: theme.$fsize3;
        line-height: 1.5;
        color: colors.$white-100;
      }

      .mrkt-hdr-card-commodity-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 6px;
        margin-right: theme.$spacing-base;
        border-radius: 50%;
        border: 2px solid colors.$white-100;
        width: 40px;
        height: 40px;
        background-color: colors.$primary-900;

        .mrkt-hdr-card-commodity-icon {
          width: 24px;
          height: 24px;
        }
      }

      .mrkt-hdr-unit-select-icon {
        height: 16px;
        width: 16px;

        &.unit-package-icon {
          margin-top: 6px;
        }

        &.unit-truck-icon {
          margin-top: 4px;
        }

        &.unit-pallet-icon {
          margin-top: 3px;
        }
      }

      .mrkt-hdr-unit-select {
        margin-top: 8px;
        margin-bottom: 0;
      }
    }
    .mrkt-hdr-card-version-wrapper {
      font-size: theme.$fsize-6;
      color: colors.$primary-100;
      line-height: 1.6;
      &:before {
        content: 'Frida V ';
      }
    }
  }

  .mrkt-hdr-card-footer {
    padding: theme.$spacing-base 0 theme.$spacing-base theme.$spacing-l;
    min-height: 51px;
  }

  .mrkt-hdr-card-hdr-content {
    align-items: center;
    display: flex;

    .mrkt-hdr-card-img {
      height: 24px;
      width: 24px;
    }

    .mrkt-hdr-card-hdr-profile-photo {
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: 50%;
      border: 2px solid colors.$white-100;

      .mrkt-hdr-card-profile-img {
        border-radius: inherit;
        height: 90%;
        width: 90%;
      }
    }

    .mrkt-hdr-card-hdr-validation-wrapper {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: theme.$spacing-base;

      .mrkt-hdr-card-hdr-validation-check-icon {
        border-radius: inherit;
        width: 72%;
        height: 72%;
        color: colors.$white-100;
      }

      &.is-validated-true {
        background-color: colors.$green-900;
      }

      &.is-validated-false {
        background-color: colors.$red-900;
      }
    }

    .mrkt-hdr-card-hdr-cntnt-date {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-left: theme.$spacing-base;

      .mrkt-hdr-card-hdr-date-icon {
        height: 18px;
        width: 18px;

        g,
        path {
          fill: colors.$white-100;
        }
      }

      .mrkt-hdr-card-hdr-date-label {
        color: colors.$white-100;
        font-size: theme.$fsize-4;
        font-weight: 400;
        line-height: 1.33;
        margin-left: theme.$spacing-base;

        b {
          font-weight: 600;
        }
      }
    }
  }
}
