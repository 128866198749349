@use 'src/styles/theme';
@use 'src/styles/colors';

.shipping-point-prices-container {
  padding-top: theme.$spacing-m;
}

.shipping-point-prices-header-title > h3 {
  color: colors.$neutral-800;
  font-size: theme.$fsize1;
  font-weight: 600;
  padding: theme.$spacing-base 0;
}

.shipping-point-prices-header-description > p {
  color: colors.$neutral-700;
  font-size: theme.$fsize-2;
  height: 48px;
  line-height: 1.71;
  width: 683px;
}

.shipping-point-prices-description {
  color: colors.$neutral-700;
  font-size: theme.$fsize-2;
  line-height: 1.71;
}

.shipping-point-prices-buttons {
  display: flex;
  margin-top: theme.$spacing-l;
}

button.shipping-point-category-button {
  background-color: colors.$white-100;
  border-color: colors.$primary-900;
  border-radius: 0px;
  color: colors.$primary-800;
  font-size: theme.$fsize-3;
  padding: theme.$spacing-base theme.$spacing-l;
}

button.shipping-point-first-button {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

button.shipping-point-last-button {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

button.shipping-point-category-button-selected {
  background-color: colors.$primary-800;
  color: colors.$white-100;
}

@media screen and (max-width: 1024px) {
  .shipping-point-prices-container {
    padding: 0;
  }

  .shipping-point-prices-buttons button {
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}
