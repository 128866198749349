@use 'src/styles/theme';
@use 'src/styles/colors';
@use 'src/styles/grid';
@use 'src/styles/table';
@use 'src/styles/labels';
@use 'src/styles/utilities/layout';
@use 'src/styles/utilities/flexbox';
@use 'src/styles/utilities/spacing';
@use 'src/styles/utilities/border';
@use 'src/styles/utilities/color';
@use 'src/styles/utilities/typography';
@use 'src/styles/utilities/hide';
@use 'src/styles/utilities/misc';

*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: acumin-pro;
}

body {
  font-size: 16px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
}

hr {
  border-bottom: unset;
  border-left: unset;
  border-right: unset;
  border-top-color: colors.$neutral-400;
  border-top-style: dashed;
  border-top-width: 1px;
}

svg {
  fill: currentColor;
}

ul[role="list"],
ol[role="list"] { list-style: none }

#modal-backdrop {
  --duration: 270ms;
  --delay-tolerance-before-transition-starts: 100ms;
  --delay-before-totally-hidden: calc(var(--duration) + 10ms);

  @media (prefers-reduced-motion) {
    --duration: 0ms;
  }

  position: fixed;
  inset: 0;
  z-index: theme.$modal-index;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color var(--duration) linear var(--delay-tolerance-before-transition-starts),
    visibility 0s linear var(--delay-before-totally-hidden);
  pointer-events: none;

  @supports not (inset: 0) {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:not(:empty) {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color var(--duration) linear, visibility 0s linear 0s;
    pointer-events: auto;
  }
}
