@use 'src/styles/theme';
@use 'src/styles/mixins/mq';

.modal-reset-password-confirm {
  .modal__content {
    max-width: 458px;
    margin: 112px auto theme.$spacing-m;

    @include mq.lt-md {
      margin-top: theme.$spacing-3xl;
      margin-bottom: theme.$spacing-3xl;
    }
  }
}

div.modal-reset-password-confirm__body {
  @include mq.lt-md {
    padding-left: theme.$spacing-l;
    padding-right: theme.$spacing-l;
  }
}
