@use 'src/styles/theme';
@use 'src/styles/colors';

.select-cascader {
  display: flex;
  padding: theme.$spacing-m;

  .select-row {
    display: inherit;
    flex-direction: column;
    margin-right: theme.$spacing-l;

    .select-label-title {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .select-cascader {
    .select-cascader-row {
      margin-bottom: 10px;
    }

    .select-row {
      flex-direction: row;
      margin-right: 0;
      &:nth-of-type(n + 2) {
        .select-label-title {
          margin-top: 10px;
        }
      }
    }

    &.no-border-select {
      button {
        border-radius: 0;
        border: 0;
      }
    }

    &.no-label-title-select {
      .select-label-title {
        display: none;
      }
    }

    &.select-cascader-column {
      border-radius: 16px;
      border: solid 1px colors.$neutral-200;
      flex-direction: column;
      max-width: 684px;
      padding: theme.$spacing-m;

      .select-row {
        flex-direction: column;
        .select-component-container {
          max-width: 100%;
          button {
            justify-content: space-between;
            margin: 0;
            padding: 11px theme.$spacing-m;
            width: 100%;
          }
          p {
            max-width: 90%;
          }
        }
      }

      .select-button-arrow-icon {
        height: 13px;
        width: 13px;
      }

      [class^='sl-floating-modal-container-'] {
        left: 5px;
        top: 42px;
        width: 185px;
      }
    }
    &.ellipsis-text-minor {
      .select-row {
        .select-component-container {
          p {
            max-width: 75px;
          }
        }
      }
    }

    &.no-arrow-select {
      .select-button-arrow-icon {
        display: none;
      }
    }

    &.box-shadow-select {
      button {
        border-bottom-right-radius: 10px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        margin-right: 3px;
        padding: theme.$spacing-base theme.$spacing-s;
      }
    }
  }
}

@media screen and (max-width: 424px) {
  .select-cascader {
    display: flex;
    flex-direction: column;

    &.no-arrow-select .select-button-arrow-icon {
      display: inherit;
      position: absolute;
      right: -5px;
    }

    &.box-shadow-select {
      button {
        width: 100%;
        box-shadow: none;
        margin-bottom: 2px;
        position: relative;
        border-radius: 0px !important;
      }
    }
  }
}
