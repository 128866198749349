@use 'src/styles/theme';
@use 'src/styles/colors';

.login-container {
  background-color: colors.$primary-600;
  overflow: hidden;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  .login-inner-container {
    max-width: 1280px;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr 100px;
  }

  .login-onboarding-container,
  .login-form-card-container {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .login-onboarding-container {
    grid-column-end: 7;
    grid-column-start: 2;
    grid-row-start: 1;
    position: relative;

    div {
      z-index: 10;
    }
  }

  .login-form-card-container {
    grid-column-end: 12;
    grid-column-start: 8;
    grid-row-start: 1;
  }
}

.login-background-1 {
  background-image: url(theme.$login-background-1);
}

.login-background-2 {
  background-image: url(theme.$login-background-2);
}

.login-background {
  background-repeat: no-repeat;
  background-size: 80% 96%;
  grid-column-end: 10;
  grid-column-start: 1;
  grid-row-start: 1;
  margin-top: 20px;
}

.login-footer-copyrights {
  grid-column-end: 7;
  grid-column-start: 2;
  grid-row-start: 2;
  display: flex;
  flex-direction: column;
  text-align: center;

  p {
    &:first-child {
      font-weight: 600;
    }
    color: colors.$white-100;
    font-size: theme.$fsize-4;
    line-height: 1.33;
    text-align: center;
  }
}

@media screen and (max-height: 700px) {
  .login-container {
    .login-inner-container {
      grid-template-rows: 1fr 33px;
    }
  }
}

@media screen and (max-width: 780px) {
  .login-container {
    height: 100%;
    width: 100%;

    .login-form-card-container {
      grid-column-end: 13;

      .login-form-container {
        width: 90%;
      }
    }

    .login-background {
      background-size: 100% 100%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .login-container {
    height: 100%;
    width: 100%;
    .login-inner-container {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr 1fr 100px;
    }

    .login-form-card-container {
      grid-column-end: 5;
      grid-column-start: 1;
      grid-row-end: 2;
      grid-row-start: 1;

      .login-form-container {
        width: 90%;
      }
    }

    .login-onboarding-container {
      grid-column-end: 5;
      grid-column-start: 1;
      grid-row-end: 3;
      grid-row-start: 2;
    }

    .login-footer-copyrights {
      grid-column-end: 5;
      grid-column-start: 1;
      grid-row-end: 5;
      grid-row-start: 4;
      padding: theme.$spacing-4xl;
    }

    .login-background {
      background-size: 200% 100%;
      grid-column-end: 5;
      grid-column-start: 1;
      grid-row-end: 5;
      grid-row-start: 1;
      margin: 0;
    }
  }
}
