@use 'src/styles/theme';
@use 'src/styles/colors';

.imported-graph-custom-yaxis-label {
  color: colors.$neutral-700;
  font-size: theme.$fsize-6;
  font-weight: 500;
  height: 24px;
  line-height: 2.4;
  width: 98px;
  margin-left: 10px;
}

.daily-graph-container {
  padding: theme.$spacing-base 0;
  position: relative;
}

.daily-graph-highcharts-ylabel {
  color: colors.$neutral-900;
  font-size: theme.$fsize-6;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 5px;
  margin-left: 10px;
}

.daily-graph-custom-xaxis-month-label {
  bottom: 50px;
  color: colors.$neutral-700;
  font-size: theme.$fsize-5;
  font-weight: 500;
  line-height: 2.4;
  position: absolute;
}

.daily-xaxis-month-left {
  left: 54px;
}

.daily-xaxis-month-right {
  right: 10px;
}

.daily-graph-description {
  color: colors.$neutral-700;
  font-size: theme.$fsize-4;
  margin: theme.$spacing-l 0;
  width: 683px;
}

.daily-divisor-responsive {
  display: inherit;
}

@media screen and (max-width: 1024px) {
  .daily-divisor-responsive {
    display: none;
  }
  .daily-graph-description {
    width: 100%;
  }
}
