@use 'src/styles/theme';
@use 'src/styles/colors';

.dayinfo-container {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
  flex-direction: column;
  height: 135px;
  width: inherit;
  p {
    display: inline-block;
  }
}

.dayinfo-border {
  border-bottom-color: rgba(205, 190, 249, 1);
}

.dayinfo-warning-border {
  border-bottom-color: #f89494;
}

.gradient-divisor {
  width: 100%;
  height: 8px;
  background-image: linear-gradient(180deg, rgba(104, 61, 237, 0.12) 0%, rgba(104, 61, 237, 0) 99%);
}

.dayinfo-warning-bg {
  background-color: colors.$red-50;
}

.dayinfo-warning-cebra-bg {
  background-color: colors.$red-100;
}

.dayinfo-cebra-bg {
  background-color: colors.$neutral-50;
}

.dayinfo-date {
  color: colors.$neutral-700;
  font-size: theme.$fsize-6;
  margin-top: 11px;
  text-transform: capitalize;
  font-weight: 500;
}

.dayinfo-temperature {
  align-items: center;
  display: flex;
  height: 32px;
}

.dayinfo-temp-high {
  color: colors.$neutral-900;
  font-size: theme.$fsize1;
  font-weight: 400;
}

.dayinfo-temp-low {
  color: colors.$neutral-700;
  font-size: theme.$fsize-base;
}

.dayinfo-temp-separator {
  color: colors.$neutral-500;
  font-size: theme.$fsize1;
  font-weight: 300px;
}

.dayinfo-f-degree:after,
.dayinfo-c-degree:after {
  color: colors.$neutral-600;
  font-size: theme.$fsize-3;
  font-weight: 400;
  line-height: 1.6;
}

.dayinfo-f-degree:after {
  content: '°F';
}

.dayinfo-c-degree:after {
  content: '°C';
}

.dayinfo-icon {
  height: 32px;
  margin: 5px 0px;
  width: 32px;
}

.dayinfo-description {
  color: colors.$neutral-800;
  font-size: theme.$fsize-6;
  font-weight: 600;
  margin-bottom: 10px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  min-width: 80px;
}

.dayinfo-general-information {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dayinfo-weather-resume {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .dayinfo-container {
    height: fit-content;
  }

  .dayinfo-information-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
}
