@use 'src/styles/theme';

.df { display: flex }
.db { display: block }
.dib { display: inline-block }
.dif { display: inline-flex }
.dn { display: none }

.pa { position: absolute }
.pr { position: relative }

.wsnw { white-space: nowrap }
.wspl { white-space: pre-line }

.h100 { height: 100% }
.w100 { width: 100% }
.h-l { height: theme.$spacing-l }

.square-m { width: theme.$spacing-m; height: theme.$spacing-m; }
.square-l { width: theme.$spacing-l; height: theme.$spacing-l; }
.square-xl { width: theme.$spacing-xl; height: theme.$spacing-xl; }

.scroll-y { overflow-y: auto }

.oh { overflow: hidden }
.oa { overflow: auto }

.ellipsis {
  @extend .db;
  @extend .oh;
  @extend .wsnw;
  text-overflow: ellipsis;
}
