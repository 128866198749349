@use 'src/styles/theme';
@use 'src/styles/colors';

.filter-select-component-container {
  position: relative;
  max-width: 155px;

  [class^='filter-select-component-button-text-'] {
    color: colors.$neutral-900;
    font-size: theme.$fsize-4;
    font-weight: 400;
    line-height: 1.33;
    margin-right: 10px;
    text-align: center;
    text-transform: capitalize;
  }

  button {
    align-items: center;
    background-color: colors.$white-100;
    border-radius: theme.$spacing-base;
    border: solid 1px colors.$neutral-300;
    display: flex;
    font-size: theme.$fsize-2;
    font-weight: 400;
    line-height: 1.14;
    outline: none;
    padding: theme.$spacing-base theme.$spacing-m;
    text-align: center;

    p {
      margin-right: 5px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover {
      cursor: pointer;
      border-color: colors.$primary-500;
    }

    &:active {
      border-color: colors.$primary-700;
    }

    &:focus {
      border-color: colors.$primary-500;
      box-shadow: 0 0 0 3px colors.$green-800-60;
    }
  }

  .filter-select-component-button-active-true {
    border-color: colors.$primary-700;
  }

  .filter-select-button-arrow-icon {
    width: 11px;
    height: 11px;

    path {
      fill: colors.$primary-900;
    }
  }
}

[class^='sl-filter-floating-modal-container-'] {
  background-color: colors.$white-100;
  border-radius: theme.$spacing-base;
  border: solid 1px colors.$neutral-300;
  box-shadow: 0 8px 8px -4px colors.$primary-800-10;
  position: absolute;
  left: 0;
  top: 40px;
  width: 155px;
  z-index: 100;

  [class^='sl-filter-floating-options-'] {
    border-radius: inherit;
    list-style-type: none;

    li {
      border-top-color: colors.$neutral-200;
      border-top-style: solid;
      border-top-width: 1px;
      color: colors.$neutral-900;
      font-size: theme.$fsize-4;
      font-weight: 400;
      line-height: 1.33;
      padding: theme.$spacing-m;

      &:hover {
        background-color: colors.$primary-50;
        color: colors.$primary-800;
        cursor: pointer;
      }
    }

    .sl-filter-floating-option-selected-true {
      color: colors.$primary-800;
    }

    li:first-child {
      border-top: 0 !important;

      &:hover {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
      }
    }

    li:last-child {
      &:hover {
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .filter-selectcomponent-container {
    [class^='filter-select-component-button-text-'] {
      font-size: theme.$fsize-5;
      margin: 3px;
      padding: 1px 4px 5px 4px;
    }
  }
}

@media screen and (max-width: 424px) {
  .filter-selectcomponent-container {
    width: 100%;

    [class^='filter-select-component-button-text-'] {
      font-size: theme.$fsize-4;
    }
  }
}
