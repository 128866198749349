@use 'src/styles/colors';

// foreground colors
.neutral-400 { color: colors.$neutral-400 }
.neutral-600 { color: colors.$neutral-600 }
.neutral-700 { color: colors.$neutral-700 }
.neutral-800 { color: colors.$neutral-800 }
.neutral-900 { color: colors.$neutral-900 }
.primary-500 { color: colors.$primary-500 }
.primary-700 { color: colors.$primary-700 }
.primary-900 { color: colors.$primary-900 }
.red-900 { color: colors.$red-900 }
.white-100 { color: colors.$white-100 }

// background colors
.inherit-bg { background-color: inherit }
.transparent-bg { background-color: transparent }
.black-50-bg { background-color: rgba(0, 0, 0, 0.5) }
.green-800-bg { background-color: colors.$green-800 }
.green-900-bg { background-color: colors.$green-900 }
.neutral-50-bg { background-color: colors.$neutral-50 }
.neutral-100-bg { background-color: colors.$neutral-100 }
.neutral-300-bg { background-color: colors.$neutral-300 }
.primary-50-bg { background-color: colors.$primary-50 }
.primary-100-bg { background-color: colors.$primary-100 }
.red-50-bg { background-color: colors.$red-50 }
.red-900-bg { background-color: colors.$red-900 }
.white-100-bg { background-color: colors.$white-100 }
.yellow-900-bg { background-color: colors.$yellow-900 }

// border colors
.neutral-50-br { border-color: colors.$neutral-50 }
.neutral-300-br { border-color: colors.$neutral-300 }
.green-500-br { border-color: colors.$green-500 }
.primary-200-br { border-color: colors.$primary-200 }
.primary-300-br { border-color: colors.$primary-300 }
.red-500-br { border-color: colors.$red-500 }
.red-600-br { border-color: colors.$red-600 }
