@use 'src/styles/theme';
@use 'src/styles/colors';

.imported-graph-custom-yaxis-label {
  color: colors.$neutral-700;
  font-size: theme.$fsize-6;
  font-weight: 500;
  height: 24px;
  line-height: 2.4;
  width: 98px;
  margin-left: 10px;
}

.weekly-graph-description {
  color: colors.$neutral-700;
  font-size: theme.$fsize-4;
  margin: theme.$spacing-l 0;
  width: 683px;
}

.weekly-divisor-responsive {
  display: inherit;
}

.weekly-graph-highcharts-container {
  padding: theme.$spacing-base 0;
}

.weekly-graph-highcharts-ylabel {
  color: colors.$neutral-900;
  font-size: theme.$fsize-6;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 5px;
  margin-left: 10px;
}

@media screen and (max-width: 1024px) {
  .weekly-graph-description {
    width: 100%;
  }
  .weekly-divisor-responsive {
    display: none;
  }
}
