@use 'src/styles/colors';
@use 'src/styles/theme';
@use 'src/components/Button/style.scss';

.informationtext-container {
  padding: theme.$spacing-xl 0;
  position: relative;
  width: 100%;
}

.infotxt-middle-section {
  color: colors.$white-100;
  font-weight: bold;
}

.infotxt-middle-content-section {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 70px 0;
}

.infotxt-middle-content-text {
  margin-left: 60px;
  width: 60%;
}

.infotxt-main-text {
  font-size: theme.$fsize3;
  line-height: 1.5;
  margin-bottom: 30px;
}

.infotxt-secondary-text {
  font-size: 1.01rem;
  width: 100%;
  margin-bottom: 30px;
}

.infotxt-icn {
  height: 100px;
  width: 100px;
}

.black-berry-icon {
  position: absolute;
  right: -50px;
  top: -50px;

  &.infotxt-right-rotate {
    transform: rotate(20deg);
  }
}

.blue-berry-icon {
  bottom: -30px;
  position: absolute;
  right: -50px;

  &.infotxt-mirror {
    transform: rotateY(180deg) rotateZ(-30deg);
  }
}

.rasp-berry-icon {
  position: absolute;
  left: -50px;
  top: 45%;
}

.infotxt-left-rotate {
  margin-bottom: 20px;
  transform: rotate(-20deg);
}

.infotxt-footer {
  bottom: -80px;
  left: 50px;
  position: absolute;
  text-align: center;
  width: 100%;

  p {
    color: colors.$white-100;
    font-size: theme.$fsize-4;
    line-height: 1.33;
  }
}

.infotxt-mailto-button {
  @extend.fk-button,.button-primary-main,.button-small;
  text-decoration: none;
}

@media screen and (max-height: 700px) {
  .informationtext-container {
    padding: theme.$spacing-m;
  }

  .infotxt-middle-content-section {
    padding: theme.$spacing-xl 0;
  }
}

@media screen and (max-width: 1024px) {
  .infotxt-middle-content-text {
    margin: 0;
    text-align: center;
  }
}
