@use 'src/styles/colors';
@use 'src/styles/theme';
@use 'src/components/Button/style.scss';

.committed-program-container {
  // TODO: Change fira sans text for the new one
  display: flex;
  flex-direction: column;

.commtd-prog-message-label {
  color: colors.$neutral-900;
  font-size: theme.$fsize-2;
  line-height: 1.71;
  margin: theme.$spacing-m 0;
}
  .commttd-prog-footer-help {
    background-color: colors.$primary-50;
    border-radius: 16px;
    margin-top: theme.$spacing-l;
    padding: theme.$spacing-xl;

    hr {
      margin-top: 7px;
    }
  }
  .commttd-prog-content {
    display: flex;
    flex-direction: column;

    .commttd-prog-content-title {
      margin-bottom: 13px;
    }
  }
}

.link-to-mail-support-message {
  @extend .fk-button, .button-secondary-main, .button-x-small;
  text-decoration: none;
}
