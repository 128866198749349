@use 'src/styles/theme';
@use 'src/styles/colors';

.product-storage--loading {
  min-height: theme.$spacing-2xl;
}

.product-storage-header {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.product-storage-header > h2 {
  color: colors.$neutral-900;
  font-size: theme.$fsize3;
  font-weight: 600;
  line-height: 1.5;
}

.product-storage-icon {
  height: 24px;
  margin-right: 10px;
  width: 24px;
}

@media screen and (max-width: 1024px) {
  .product-storage-container {
    padding-left: 0;
  }
}
