@use 'src/styles/theme';
@use 'src/styles/colors';

.date-picker-container {
  display: flex;
  z-index: 15;

  &:hover {
    > .dt-picker-calendar-icon-default {
      display: none;
    }

    > .dt-picker-calendar-icon-edit {
      display: block;
    }
  }
}

.dt-picker-inner-container {
  color: colors.$primary-900;
  display: flex;
  font-size: 0.8rem;
}

.dt-picker-calendar-icon-default {
  height: 18px;
  margin-right: 5px;
  width: 18px;

  g,
  path {
    fill: colors.$primary-900;
  }
}

.dt-picker-calendar-icon-edit {
  height: 18px;
  margin-right: 5px;
  width: 18px;
  display: none;
}

.dt-picker-button {
  border: 0;
  color: colors.$primary-900;
  font-weight: 500;
  background-color: colors.$white-100;
  outline: none;

  span {
    font-weight: 400;
  }

  // more clickeable area
  &:before {
    content: '';
    cursor: pointer;
    height: 30px;
    left: -30px;
    position: absolute;
    top: -8px;
    width: 157px;
  }

  &:hover {
    text-decoration: underline;
  }
}

// Overwrite datepicker

.dt-picker-container {
  background-color: colors.$white-100;
  border-radius: theme.$spacing-base;
  border: solid 1px colors.$neutral-300;
  box-shadow: 0 1px 8px 0 colors.$neutral-800-10;
  padding: 16px;
  z-index: 15;

  &:before {
    content: url('../assets/icons/svg/Tip.svg');
    position: absolute;
    top: -12px;
  }

  .dt-picker-container-children {
    position: relative;
  }
}

.dt-picker-component {
  border: 0 !important;

  .dt-picker-header {
    display: flex;
    justify-content: space-evenly;
  }

  .react-datepicker__header {
    background-color: colors.$white-100;
    border-bottom: 0;

    select {
      appearance: none;
      border-radius: theme.$spacing-base;
      border: solid 1px colors.$primary-100;
      color: colors.$primary-900;
      font-size: theme.$fsize-2;
      outline: none;
      padding-bottom: theme.$spacing-base;
      padding-top: theme.$spacing-base;
      text-align-last: center;
      text-transform: uppercase;
      width: 100px;

      &:hover {
        cursor: pointer;
        background-color: colors.$primary-50;
      }

      &:active,
      &:focus {
        background-color: colors.$primary-900;
        color: colors.$white-100;
      }
    }
  }

  .react-datepicker__day {
    color: colors.$neutral-800;
    outline: none;

    &:hover {
      background-color: colors.$primary-50;
      border-radius: 8px;
      color: colors.$primary-900;
    }
  }

  .react-datepicker__day--outside-month {
    color: colors.$neutral-600;
  }

  .react-datepicker__day--selected {
    background-color: colors.$primary-900;
    border-radius: 8px;
    color: colors.$white-100 !important;

    &:hover {
      background-color: colors.$primary-900;
      border-radius: 8px;
      color: colors.$white-100 !important;
    }
  }

  .react-datepicker__day--today {
    color: colors.$primary-900;
  }

  .react-datepicker__day--disabled {
    color: colors.$neutral-300;

    &:hover {
      color: colors.$neutral-300;
      background-color: colors.$white-100;
    }
  }
}

// EXTRAS
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: colors.$primary-900 !important;
  color: colors.$white-100 !important;
}

@media screen and (max-width: 1024px) {
  .dt-picker-button {
    font-size: theme.$fsize-5;
  }
}

@media screen and (max-width: 320px) {
  .dt-picker-button {
    font-size: theme.$fsize-6;
  }
}
