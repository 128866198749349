@use 'src/styles/theme';

// padding
.p-m { padding: theme.$spacing-m }
.p-l { padding: theme.$spacing-l }
.p-xl { padding: theme.$spacing-xl }

.pt-base { padding-top: theme.$spacing-base }
.pt-m { padding-top: theme.$spacing-m }
.pt-l { padding-top: theme.$spacing-l }

.pr-s { padding-right: theme.$spacing-s }
.pr-l { padding-right: theme.$spacing-l }
.pr-xl { padding-right: theme.$spacing-xl }
.pr-2xl { padding-right: theme.$spacing-2xl }

.pb-base { padding-bottom: theme.$spacing-base }
.pb-m { padding-bottom: theme.$spacing-m }
.pb-l { padding-bottom: theme.$spacing-l }
.pb-xl { padding-bottom: theme.$spacing-xl }
.pb-3xl { padding-bottom: theme.$spacing-3xl }

.pl-base { padding-left: theme.$spacing-base }
.pl-m { padding-left: theme.$spacing-m }
.pl-l { padding-left: theme.$spacing-l }

.px-base { padding-left: theme.$spacing-base; padding-right: theme.$spacing-base; }
.px-l { padding-left: theme.$spacing-l; padding-right: theme.$spacing-l; }
.px-xl { padding-left: theme.$spacing-xl; padding-right: theme.$spacing-xl; }
.px-3xl { padding-left: theme.$spacing-3xl; padding-right: theme.$spacing-3xl; }

.py-s { padding-top: theme.$spacing-s; padding-bottom: theme.$spacing-s; }
.py-m { padding-top: theme.$spacing-m; padding-bottom: theme.$spacing-m; }
.py-l { padding-top: theme.$spacing-l; padding-bottom: theme.$spacing-l; }
.py-xl { padding-top: theme.$spacing-xl; padding-bottom: theme.$spacing-xl; }

// margin
.mr-m { margin-right: theme.$spacing-m }
.mr-base { margin-right: theme.$spacing-base }

.ml-s { margin-left: theme.$spacing-s }
.ml-base { margin-left: theme.$spacing-base }

.mb0 { margin-bottom: 0 }
.mb-m { margin-bottom: theme.$spacing-m }
.mb-l { margin-bottom: theme.$spacing-l }
