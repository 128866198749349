@use 'src/styles/theme';

.opportunity-card-container {
  border-radius: 16px;
  user-select: none;

  > div {
    border-radius: inherit;
    padding: theme.$spacing-m theme.$spacing-xl;
  }

  .opportunity-card-header {
    h3 {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 1.5;
    }
  }
}
